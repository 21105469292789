import { RCBC_ADDRESS } from 'core/tron/tron.consts'

export const getBlockchainLinks = () => {
    const ref =
        process.env.NODE_ENV === 'production'
            ? 'https://tronscan.io/#/contract/'
            : 'https://nile.tronscan.io/#/contract/'

    return {
        token: `${ref}${RCBC_ADDRESS}`,
    }
}
