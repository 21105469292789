import React, {
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
} from 'react'
import { LoaderContext } from 'core/context/Loader'
import { AccountContext } from './AccountContext'
import { AccountService, AuthService } from 'api/http'
import { TronLink } from 'core/tron'
import { notification } from 'antd'
import { useFetch } from 'shared/hooks'

export const AccountContextProvider: FC<PropsWithChildren> = React.memo(
    ({ children }) => {
        const { setLoader } = useContext(LoaderContext)
        const [onMeFetch, accountData] = useFetch(AccountService.getMe)

        /** Авторизация через тронлинк */
        const tronLinkAuth = useCallback(async () => {
            try {
                setLoader(true)

                if (TronLink.base58Address) {
                    const { nonce, isNew } = await AuthService.getNonce({
                        address: TronLink.base58Address,
                    })
                    if (isNew) {
                        notification.error({
                            message: 'Auth error',
                            description: 'Unregister address tron',
                        })
                        return
                    }

                    const sign = await TronLink.signMessage(nonce)
                    if (!sign) {
                        return
                    }
                    await AuthService.tronVerify({
                        sign,
                        nonce,
                        address: TronLink.base58Address,
                    })
                    await onMeFetch()
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, onMeFetch])

        /** Регистрация через тронлинк */
        const tronLinkRegister = useCallback(async () => {
            try {
                setLoader(true)

                if (TronLink.base58Address) {
                    const { nonce, isNew } = await AuthService.getNonce({
                        address: TronLink.base58Address,
                    })
                    if (!isNew) {
                        notification.error({
                            message: 'Registration error',
                            description: 'Tron address is exist',
                        })
                        return
                    }

                    const sign = await TronLink.signMessage(nonce)
                    if (!sign) {
                        return
                    }
                    await AuthService.tronVerify({
                        sign,
                        nonce,
                        address: TronLink.base58Address,
                    })
                    await onMeFetch()
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, onMeFetch])

        useEffect(() => {
            onMeFetch()
        }, [])

        useEffect(() => {
            if (TronLink.tronWeb) {
                return
            }

            let counter = 0
            setLoader(true)
            const intervalId = setInterval(() => {
                counter++
                console.log(`Connecting TronLink ${counter}...`)

                if (window?.tronLink) {
                    clearInterval(intervalId)
                    TronLink.initTronWeb()
                        .then(() => {
                            console.log('TronLink is connected')
                        })
                        .finally(() => {
                            setLoader(false)
                        })
                } else if (counter === 3) {
                    clearInterval(intervalId)
                    setLoader(false)
                    console.log('TronLink is not connected')
                }
            }, 1000)
        }, [setLoader])

        return (
            <AccountContext.Provider
                value={{
                    accountData,
                    tronLinkRegister,
                    auth: onMeFetch,
                    tronLinkAuth,
                }}
            >
                {children}
            </AccountContext.Provider>
        )
    }
)
