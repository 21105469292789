import s from './Header.module.scss'

import React from 'react'
import { ReactComponent as BinanceLogo } from 'shared/img/binance-icon.svg'
import { ReactComponent as BybitLogo } from 'shared/img/bybit-icon.svg'
import RithmicLogo from 'shared/img/rithmic-icon.png'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { Link } from 'react-router-dom'
import { NAV_LINK_NAMES } from 'shared/consts'

/**
 * Элемент ссылки для меню
 * @param label название
 * @param link ссылка
 */
export const getMenuLink = (label: string, link: string) => (
    <Link to={link}>{label}</Link>
)

export const MENU_ITEM: ItemType[] = [
    {
        label: 'Trade',
        key: 'trade',
        children: [
            {
                label: 'Crypto',
                key: 'crypto',
                children: [
                    {
                        label: getMenuLink(
                            'Binance',
                            NAV_LINK_NAMES.TRADING_BINANCE
                        ),
                        key: 'robotBinance',
                        icon: <BinanceLogo className={s.itemLogo} />,
                    },
                    {
                        label: getMenuLink(
                            'ByBit',
                            NAV_LINK_NAMES.TRADING_BYBIT
                        ),
                        key: 'robotBybit',
                        icon: <BybitLogo className={s.itemLogo} />,
                    },
                ],
            },
            {
                label: 'Global',
                key: 'global',
                children: [
                    {
                        label: getMenuLink(
                            'Rithmic',
                            NAV_LINK_NAMES.TRADING_RITHMIC
                        ),
                        key: 'robotRithmic',
                        icon: (
                            <img
                                src={RithmicLogo}
                                className={s.itemLogo}
                                alt="Rithmic"
                            />
                        ),
                    },
                ],
            },
        ],
    },
]

export const ADMIN_MENU_ITEM: ItemType[] = [
    {
        label: 'Binance',
        key: 'binance',
        children: [
            {
                label: getMenuLink('Accounts', NAV_LINK_NAMES.BINANCE_ACCOUNTS),
                key: 'binanceAccounts',
            },
            {
                label: getMenuLink('Symbols', NAV_LINK_NAMES.BINANCE_SYMBOLS),
                key: 'binanceSymbols',
            },
        ],
    },
    {
        label: 'ByBit',
        key: 'bybit',
        children: [
            {
                label: getMenuLink('Accounts', NAV_LINK_NAMES.BYBIT_ACCOUNTS),
                key: 'bybitAccounts',
            },
            {
                label: getMenuLink('Symbols', NAV_LINK_NAMES.BYBIT_SYMBOLS),
                key: 'bybitSymbols',
            },
        ],
    },
    {
        label: 'FCM',
        key: 'FCM',
        children: [
            {
                label: 'Advanced Futures',
                key: 'advanced',
                children: [
                    {
                        label: getMenuLink(
                            'Accounts',
                            NAV_LINK_NAMES.RITHMIC_ACCOUNTS
                        ),
                        key: 'rithmicAccounts',
                    },
                ],
            },
        ],
    },
]
