export const translations = {
    en: {
        about: {
            learnMore: 'Learn more about RealColibri',
            autotrading: {
                header: 'Automated Trading',
                welcome: 'Welcome to the world of high-tech trading with RealColibri!',
                description: `RealColibri is an advanced automated trading system specializing in futures
                              contracts on cryptocurrency exchanges and global markets. Our system is characterized
                              by outstanding long-term success and an innovative approach to investing.`,
                unique: 'What makes the RealColibri system unique?',
                list: [
                    'Outstanding results: RealColibri delivers impressive long-term results. Our system is based on advanced algorithms and thorough data analysis, allowing us to achieve stable and significant investment growth while minimizing risks.',
                    'Automated approach: Forget the need to constantly monitor the market! RealColibri automatically analyzes thousands of data points and makes trading decisions in real-time, freeing you from stress and increasing your investment efficiency.',
                    'Diversification: The ability to trade different instruments within one account by creating additional API key pairs or login-password combinations and using them on multiple RealColibri accounts.',
                    'Using the RCC token: RealColibri uses RCC as a service fee. The commission is calculated as a percentage of the income received on the exchange account. If the value of RCC increases, the client pays a smaller percentage, increasing their net profit. This makes using the token more beneficial than selling it.',
                    'Free trial period: Try our algorithm for free for one week! You can also extend this period for another week for each client you invite to our system. Read more about this feature in the FAQ section.',
                ],
            },
            realColibriCoin: {
                header: 'RealColibri Coin',
                welcome: 'Explore new horizons with RealColibri Coin!',
                description: `RealColibri Coin (RCC) is a utility token used exclusively within the RealColibri ecosystem. Listing on exchanges is not required as its nominal value is secured in the smart contract. You can always purchase RCC directly through the smart contract until the allocated token supply is exhausted. After that, for free circulation, listing on centralized or decentralized exchanges may be considered.`,
                list: [
                    'Beneficial commission: The higher the value of RCC, the fewer tokens will be charged for RealColibri’s automated trading services, making them even more accessible and attractive to traders.',
                    'Value growth with usage: Using RCC to pay for automated trading fees increases its value. Unlike direct sales, where RCC is exchanged for a fixed value, using it for commissions multiplies its value twofold or more. For example, if RCC was bought for $1 and its value increased tenfold, the commission decreases from 50% to 5%, leaving 95% as net income.',
                    'Token exchange: Our ecosystem also allows users to sell/exchange RCC with each other without RealColibri’s involvement.',
                ],
                whatCanIDo: 'What can I do with RCC?',
                actions: [
                    'Top up the commission deposit and use RCC as a commission fee for automated trading, increasing its value.',
                    'Sell directly to another RealColibri participant who wants to purchase RCC.',
                    'Trade RCC on exchanges.',
                ],
                joinNow: 'Join RealColibri today and unlock new opportunities and paths to financial success!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'How do I start trading?',
                        answer: `To start automated trading, you need to: <br/>
                                Register on our website and connect your TronLink wallet. <br/>
                                Purchase RCC and top up your commission deposit in your RealColibri account. <br/>
                                Connect your trading account on the trading page using API keys,
                                select a trading instrument, and start the algorithm.`,
                    },
                    {
                        question: 'Where does the profit go?',
                        answer: 'The profit remains fully on your exchange account.',
                    },
                    {
                        question: 'How can I purchase RCC?',
                        answer: `Use the form in your RealColibri account or on
                                the Global Markets and Cryptocurrency Markets pages.`,
                    },
                ],
            },
        },
    },
    es: {
        about: {
            learnMore: 'Aprenda más sobre RealColibri',
            autotrading: {
                header: 'Trading Automatizado',
                welcome: '¡Bienvenido al mundo del trading de alta tecnología con RealColibri!',
                description: `RealColibri es un sistema avanzado de trading automatizado especializado en futuros
                              de criptomonedas en intercambios y mercados globales. Nuestro sistema se caracteriza
                              por un éxito excepcional a largo plazo y un enfoque innovador en la inversión.`,
                unique: '¿Qué hace único al sistema RealColibri?',
                list: [
                    'Resultados sobresalientes: RealColibri ofrece resultados impresionantes a largo plazo. Nuestro sistema se basa en algoritmos avanzados y un análisis de datos detallado, lo que nos permite lograr un crecimiento estable y significativo en las inversiones mientras minimizamos los riesgos.',
                    'Enfoque automatizado: ¡Olvídese de la necesidad de monitorear el mercado constantemente! RealColibri analiza automáticamente miles de puntos de datos y toma decisiones comerciales en tiempo real, liberándolo del estrés y aumentando su eficiencia en la inversión.',
                    'Diversificación: Posibilidad de operar con diferentes instrumentos en una sola cuenta creando pares de claves API adicionales o combinaciones de inicio de sesión y contraseña y usándolos en múltiples cuentas de RealColibri.',
                    'Uso del token RCC: RealColibri usa RCC como tarifa por sus servicios. La comisión se calcula como un porcentaje de los ingresos obtenidos en la cuenta del intercambio. Si el valor de RCC aumenta, el cliente paga un porcentaje menor, aumentando su ganancia neta. Esto hace que usar el token sea más beneficioso que venderlo.',
                    'Período de prueba gratuito: ¡Pruebe nuestro algoritmo gratis durante una semana! También puede extender este período por una semana adicional por cada cliente que invite a nuestro sistema. Lea más sobre esta función en la sección de preguntas frecuentes.',
                ],
            },
            realColibriCoin: {
                header: 'Moneda RealColibri',
                welcome: '¡Explora nuevos horizontes con la moneda RealColibri!',
                description: `RealColibri Coin (RCC) es un token utilitario utilizado exclusivamente dentro del ecosistema de RealColibri. No es necesario listarlo en intercambios, ya que su valor nominal está asegurado en el contrato inteligente. Siempre puede comprar RCC directamente a través del contrato inteligente hasta que se agote la oferta asignada. Después de eso, para su libre circulación, se puede considerar su listado en intercambios centralizados o descentralizados.`,
                list: [
                    'Comisión beneficiosa: Cuanto mayor sea el valor de RCC, menos tokens se cobrarán por los servicios de trading automatizado de RealColibri, haciéndolos aún más accesibles y atractivos para los traders.',
                    'Crecimiento del valor con el uso: Usar RCC para pagar comisiones de trading automatizado aumenta su valor. A diferencia de la venta directa, donde RCC se intercambia por un valor fijo, su uso en comisiones multiplica su valor dos veces o más. Por ejemplo, si RCC se compró por $1 y su valor aumentó diez veces, la comisión disminuye del 50% al 5%, dejando el 95% como ingreso neto.',
                    'Intercambio de tokens: Nuestro ecosistema también permite a los usuarios vender/intercambiar RCC entre sí sin la intervención de RealColibri.',
                ],
                whatCanIDo: '¿Qué puedo hacer con RCC?',
                actions: [
                    'Recargar el depósito de comisión y usar RCC como tarifa de comisión para el trading automatizado, aumentando su valor.',
                    'Vender directamente a otro participante de RealColibri que desee comprar RCC.',
                    'Operar con RCC en intercambios.',
                ],
                joinNow: '¡Únase a RealColibri hoy y desbloquee nuevas oportunidades y caminos hacia el éxito financiero!',
            },
            faq: {
                header: 'Preguntas Frecuentes',
                questions: [
                    {
                        question: '¿Cómo empiezo a operar?',
                        answer: `Para comenzar a operar automáticamente, necesita: <br/>
                                Registrarse en nuestro sitio web y conectar su billetera TronLink. <br/>
                                Comprar RCC y recargar su depósito de comisión en su cuenta de RealColibri. <br/>
                                Conectar su cuenta de trading en la página de operaciones usando claves API,
                                seleccionar un instrumento de trading y comenzar el algoritmo.`,
                    },
                    {
                        question: '¿Dónde va la ganancia?',
                        answer: 'La ganancia permanece completamente en su cuenta de intercambio.',
                    },
                    {
                        question: '¿Cómo puedo comprar RCC?',
                        answer: `Utilice el formulario en su cuenta de RealColibri o en
                                las páginas de Mercados Globales y Mercados de Criptomonedas.`,
                    },
                ],
            },
        },
    },    
    ru: {
        about: {
            learnMore: 'Узнайте больше о RealColibri',
            autotrading: {
                header: 'Автоматическая торговля',
                welcome: 'Добро пожаловать в мир высокотехнологичной торговли с RealColibri!',
                description: `RealColibri — это передовая автоматизированная система торговли, специализирующаяся на фьючерсных
                              контрактах криптовалютных бирж и глобальных рынков. Наша система характеризуется
                              выдающимися долгосрочными успехами и инновационным подходом к инвестированию.`,
                unique: 'Что делает систему RealColibri уникальной?',
                list: [
                    'Превосходные результаты: RealColibri обеспечивает впечатляющие результаты в долгосрочной перспективе. Наша система основана на передовых алгоритмах и тщательном анализе данных, что позволяет нам достигать стабильного и значительного роста инвестиций, а также минимизировать риски.',
                    'Автоматический подход: Забудьте о необходимости постоянно следить за рынком! RealColibri автоматически анализирует тысячи точек данных и принимает торговые решения в реальном времени, освобождая вас от стресса и повышая вашу инвестиционную эффективность.',
                    'Диверсификация: Возможность торговать разными инструментами в одной учетной записи, создавая дополнительные пары ключей API или логин-пароль и используя их на нескольких учетных записях RealColibri.',
                    'Использование токена RCC: RealColibri использует RCC в качестве комиссии за услуги. Комиссия рассчитывается в процентах от дохода, полученного на аккаунте биржи. Если стоимость RCC выросла, клиент платит меньший процент, увеличивая свою чистую прибыль. Это делает использование токена выгоднее, чем его продажа.',
                    'Бесплатный пробный период: Попробуйте наш алгоритм бесплатно в течение одной недели! Вы также можете продлить этот период на еще одну неделю за каждого клиента, которого вы пригласите в нашу систему. Подробнее об этой функции читайте в разделе FAQ.',
                ],
            },
            realColibriCoin: {
                header: 'Монета RealColibri',
                welcome: 'Откройте новые горизонты с монетой RealColibri!',
                description: `RealColibri Coin (RCC) — это утилити-токен, используемый исключительно внутри экосистемы RealColibri. Листинг на биржах для него не требуется, поскольку его номинальная стоимость закреплена в смарт-контракте. Вы всегда можете приобрести RCC напрямую через смарт-контракт до исчерпания выделенного количества токенов. После этого, для обеспечения свободного обращения, возможен листинг на централизованных или децентрализованных биржах.`,
                list: [
                    'Выгодная комиссия: Чем выше стоимость RCC, тем меньше токенов будет взиматься за услуги автоматизации торговли RealColibri, делая их еще более доступными и привлекательными для трейдеров.',
                    'Рост ценности при использовании: Использование RCC для оплаты комиссий в автоматизированной торговле повышает его ценность. В отличие от прямой продажи, где RCC обменивается на фиксированную стоимость, при оплате комиссий его стоимость увеличивается в 2 и более раз. Например, если RCC куплен по 1 доллару, а его стоимость выросла в 10 раз, комиссия снижается с 50% до 5%, а 95% остаётся в виде чистого дохода.',
                    'Обмен токенов: Наша экосистема также позволяет пользователям продавать/обменивать RCC друг с другом без участия RealColibri.',
                ],
                whatCanIDo: 'Что я могу сделать с RCC?',
                actions: [
                    'Пополнить комиссионный депозит и использовать RCC в качестве комиссии за автоматическую торговлю, что увеличивает его ценность.',
                    'Продать напрямую другому участнику RealColibri, который желает приобрести RCC.',
                    'Торговать RCC на биржах',
                ],
                joinNow: 'Присоединяйтесь к RealColibri сегодня и откройте новые возможности и пути к финансовому успеху!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Как начать торговать?',
                        answer: `Чтобы начать автоматическую торговлю, вам нужно: <br/>
                            Зарегистрируйтесь на нашем сайте и подключите кошелек TronLink. <br/>
                            Приобретите RCC и пополните комиссионный депозит в своем аккаунте RealColibri. <br/>
                            Подключите свою торговую учетную запись на странице торговли, используя API-ключи,
                            выберите торговый инструмент и запустите алгоритм.`,
                    },
                    {
                        question: 'Куда идет прибыль?',
                        answer: 'Полученная прибыль остается на вашем биржевом счете в полном объеме.',
                    },
                    {
                        question: 'Как приобрести RCC?',
                        answer: `Используйте форму в своем аккаунте RealColibri или на
                            страницах Глобальные рынки и Криптовалютные рынки.`,
                    },
                ],
            },
        },
    }, 
    hy: {
        about: {
            learnMore: 'Իմացեք ավելին RealColibri-ի մասին',
            autotrading: {
                header: 'Ավտոմատ առևտուր',
                welcome:
                    'Բարի գալուստ բարձր տեխնոլոգիական առևտրի աշխարհ՝ RealColibri-ի հետ!',
                description: `RealColibri-ն առաջատար ավտոմատացված առևտրի համակարգ է, որը մասնագիտացված է կրիպտոարժույթների բորսաների
                          և համաշխարհային շուկաների ֆյուչերսային պայմանագրերի վրա։ Մեր համակարգը բնութագրվում է երկարաժամկետ հաջողություններով
                          և նորարարական ներդրումային մոտեցմամբ։`,
                unique: 'Ինչ է դարձնում RealColibri համակարգը եզակի?',
                list: [
                    'Բարձր արդյունքներ: RealColibri-ն ապահովում է տպավորիչ արդյունքներ երկարաժամկետ հեռանկարում։ Մեր համակարգը հիմնված է առաջադեմ ալգորիթմների վրա և մանրակրկիտ տվյալների վերլուծությամբ, ինչը թույլ է տալիս հասնել կայուն և նշանակալի ներդրումային աճին, ինչպես նաև նվազեցնել ռիսկերը։',
                    'Ավտոմատացված մոտեցում: Մոռացեք շուկան մշտապես հետևելու անհրաժեշտության մասին: RealColibri-ն ավտոմատ կերպով վերլուծում է հազարավոր տվյալների կետեր և իրական ժամանակում ընդունում առևտրային որոշումներ, ազատելով ձեզ սթրեսից և բարձրացնելով ձեր ներդրումային արդյունավետությունը:',
                    'Դիվերսիֆիկացիա: Մեկ հաշվում հնարավոր է տարբեր գործիքներով առևտուր իրականացնել, ստեղծելով հավելյալ API բանալիների զույգեր կամ լոգին-գաղտնաբառ և օգտագործելով դրանք մի քանի RealColibri հաշիվներում:',
                    'RCC թոքենի օգտագործումը: RealColibri-ն օգտագործում է RCC որպես ծառայությունների միջնորդավճար 50%-ով հանած գումարի գումարից: Միջնորդավճարային դեպոզիտը ծախսվում է յուրաքանչյուր գործարքի վրա: Այս նորարարական լուծումը թույլ է տալիս ներդրողներին "կերակրել" ալգորիթմը առևտրի համար՝ ապահովելով նրա մշտական աշխատանքը և ներդրումային գործընթացի օպտիմալացումը։ Մեկ RCC ծախսելով միջնորդավճարով, ներդրողը ստանում է կրկնակի ավելին իր առևտրային հաշվին! Բացի այդ, RCC արժեքի շարունակական աճի շնորհիվ RealColibri-ի միջնորդավճարը ժամանակի ընթացքում դառնում է ավելի փոքր և ավելի փոքր:',
                    'Անվճար փորձաշրջան: Փորձեք մեր ալգորիթմը անվճար մեկ շաբաթվա ընթացքում: Դուք կարող եք նաև երկարաձգել այս շրջանը յուրաքանչյուր հաճախորդի համար, որին դուք հրավիրեք մեր համակարգում։ Այս գործառույթի մասին ավելի մանրամասն կարդացեք FAQ բաժնում:',
                ],
            },
            realColibriCoin: {
                header: 'RealColibri մետաղադրամ',
                welcome: 'Բացահայտեք նոր հորիզոններ RealColibri մետաղադրամով!',
                description:
                    'RCC-ն պարզապես կրիպտոարժույթ չէ։ Դա մեր էկոհամակարգի հիմնական տարրն է, որը նպաստում է նրա շարունակական աճին և զարգացմանը։ Կարծեք RCC-ի մասին որպես վառելիք, որը սնուցում է մեր ծառայությունները և դրանք դարձնում ավելի հզոր և արդյունավետ: RCC-ի շարունակական շրջանառությունը RealColibri էկոհամակարգում նպաստում է թոքենի արժեքի աճին և ստեղծում բարենպաստ միջավայր բոլոր մասնակիցների համար։',
                list: [
                    'Հարմարավետ միջնորդավճար: Որքան բարձր է RCC-ի արժեքը, այնքան պակաս թոքեններ են պահանջվում RealColibri-ի ավտոմատացված առևտրային ծառայությունների համար, դարձնելով դրանք ավելի մատչելի և գրավիչ առևտրականների համար։',
                    'Թոքենների փոխանակում: Մեր էկոհամակարգը նաև թույլ է տալիս օգտատերերին վաճառել/փոխանակել RCC առանց RealColibri-ի միջամտության։ Ապագայում, երբ մենք կցուցենք RCC-ն կենտրոնացված և դեչենտրալիզացված բորսաներում, թոքենը կլինի առևտրի առարկա։ Սա ձեզ ավելի շատ ազատություն և ճկունություն է տալիս ձեր ակտիվների կառավարմանը։',
                ],
                whatCanIDo: 'Ինչ կարող եմ անել RCC-ով?',
                actions: [
                    'Լրացնել միջնորդավճարային դեպոզիտը և օգտագործել այն որպես ավտոմատացված առևտրի ծառայության համար միջնորդավճար։',
                    'Վաճառել անմիջապես այլ RealColibri-ի մասնակցի, ով ցանկանում է ձեռք բերել RCC։',
                    'Առևտրել դրանցով բորսաներում, երբ RCC-ն ցուցակագրվի ապագայում։',
                ],
                joinNow:
                    'Միացեք RealColibri-ին այսօր և բացահայտեք նոր հնարավորություններ ու ուղիներ դեպի ֆինանսական հաջողություն!',
            },
            faq: {
                header: 'Հաճախակի տրվող հարցեր',
                questions: [
                    {
                        question: 'Ինչպես սկսել առևտուրը?',
                        answer: `Ավտոմատ առևտուր սկսելու համար ձեզ անհրաժեշտ է: <br/>
                         Գրանցվեք մեր կայքում և կապեք TronLink դրամապանակը։ <br/>
                         Գնեք RCC և լրացրեք միջնորդավճարային դեպոզիտը ձեր RealColibri հաշվում։ <br/>
                         Կապեք ձեր առևտրային հաշիվը առևտրի էջում՝ օգտագործելով API բանալիներ, 
                         ընտրեք առևտրային գործիքը և մեկնարկեք ալգորիթմը։`,
                    },
                    {
                        question:
                            'Որքա՞ն գումարով կարող եմ սկսել առևտուրը և ինչպիսի՞ եկամուտ կունենամ:',
                        answer: `Նվազագույն դեպոզիտը 10,000 USDT է։ Օպտիմալ դեպոզիտի չափը պետք է լինի առնվազն
                         3.75 անգամ ավելի մեծ նվազագույն դեպոզիտից։ Եթե դեպոզիտի չափը ավելի փոքր է օպտիմալից,
                         ամսական եկամուտը կախված կլինի նվազագույն դեպոզիտից։ Եթե դեպոզիտի չափը
                         մեծ է օպտիմալից, բարդ տոկոսների հաշվարկը կիրարկվում է, և ամսական եկամուտը կախված կլինի
                         դեպոզիտի ընթացիկ չափից և կավելանա դեպոզիտի չափին համամասնորեն։ Ամեն 5% շահույթը ավելացնում է
                         պոզիցիայի ծավալը 5%-ով։ Հետևաբար, որքան մեծ է դեպոզիտը, այնքան բարձր է եկամուտը։`,
                    },
                    {
                        question: 'Կարո՞ղ եմ փորձել ավտոմատ առևտուրը անվճար:',
                        answer: `Այո, մենք առաջարկում ենք մեկ շաբաթ անվճար ալգորիթմից օգտվելու հնարավորություն։
                Այս ժամանակահատվածում ալգորիթմը գործարկելու համար ձեզ հարկավոր չէ ունենալ RCC:
                Ձեր անվճար փորձաշրջանն ակտիվացնելու համար նամակ ուղարկեք mail@realcolibri.com հասցեին՝
                ձեր խնդրանքով և ձեր Tronlink դրամապանակի հասցեն, որով գրանցվել եք մեր կայքում, և մենք ձեզ հնարավորինս շուտ հասանելիություն կտրամադրենք:
                Յուրաքանչյուր ակտիվ հաճախորդ, որը դուք հրավիրում եք մեր համակարգ, երաշխավորված է ձեզ լրացուցիչ մեկ շաբաթ անվճար ժամկետով: Պարզապես հրավիրեք ձեր ընկերոջը,
                տրամադրեք նրանց TronLink դրամապանակի հասցեն, որը գրանցված է մեր համակարգում, և դուք կստանաք ևս մեկ շաբաթ անվճար փորձաշրջան:
                Դա անելու համար յուրաքանչյուր հաճախորդ պետք է գրանցված լինի RealColibri համակարգում TronLink-ի միջոցով (Full Access)՝ անվճար առևտրային փորձարկում ստանալու համար:`,
                    },
                    {
                        question: 'Ո՞ւր է գնում շահույթը:',
                        answer: 'Ստացված շահույթը մնում է ձեր բորսային հաշվին ամբողջությամբ։',
                    },
                    {
                        question: 'Ինչպես ձեռք բերել RCC?',
                        answer: `Օգտագործեք ձեր RealColibri հաշվի ձևը կամ
                         Գլոբալ շուկաներ և Կրիպտոարժույթների շուկաներ էջերը։`,
                    },
                    {
                        question: 'Ինչ կարող եմ անել RCC-ով?',
                        answer: `Լրացրեք միջնորդավճարային դեպոզիտը և օգտագործեք այն որպես ավտոմատացված առևտրի համար միջնորդավճար։ <br/>
                         Վաճառեք անմիջապես այլ RealColibri մասնակցի, ով ցանկանում է ձեռք բերել RCC։ <br/>
                         Առևտրեք դրանցով բորսաներում, երբ RCC-ն ցուցակագրվի ապագայում։`,
                    },
                ],
            },
        },
    },
    de: {
        about: {
            learnMore: 'Erfahren Sie mehr über RealColibri',
            autotrading: {
                header: 'Automatisierter Handel',
                welcome: 'Willkommen in der Welt des hochmodernen Handels mit RealColibri!',
                description: `RealColibri ist ein fortschrittliches automatisiertes Handelssystem, das sich auf
                              Futures-Kontrakte für Kryptowährungsbörsen und globale Märkte spezialisiert hat. Unser System
                              zeichnet sich durch langfristigen Erfolg und einen innovativen Investitionsansatz aus.`,
                unique: 'Was macht das RealColibri-System einzigartig?',
                list: [
                    'Hervorragende Ergebnisse: RealColibri liefert beeindruckende langfristige Ergebnisse. Unser System basiert auf fortschrittlichen Algorithmen und einer gründlichen Datenanalyse, was es uns ermöglicht, ein stabiles und erhebliches Wachstum der Investitionen zu erzielen und gleichzeitig Risiken zu minimieren.',
                    'Automatisierter Ansatz: Vergessen Sie die Notwendigkeit, den Markt ständig zu überwachen! RealColibri analysiert automatisch Tausende von Datenpunkten und trifft Handelsentscheidungen in Echtzeit, wodurch Sie von Stress befreit werden und Ihre Investitionseffizienz gesteigert wird.',
                    'Diversifikation: Möglichkeit, verschiedene Instrumente in einem Konto zu handeln, indem zusätzliche API-Schlüsselpaare oder Login-Passwort-Kombinationen erstellt und auf mehreren RealColibri-Konten verwendet werden.',
                    'Verwendung des RCC-Tokens: RealColibri verwendet RCC als Servicegebühr. Die Gebühr wird als Prozentsatz des auf dem Börsenkonto erzielten Einkommens berechnet. Wenn der Wert von RCC steigt, zahlt der Kunde einen geringeren Prozentsatz, wodurch sein Nettogewinn steigt. Dies macht die Nutzung des Tokens vorteilhafter als dessen Verkauf.',
                    'Kostenlose Testphase: Testen Sie unseren Algorithmus eine Woche lang kostenlos! Sie können diese Periode auch um eine weitere Woche verlängern, wenn Sie einen neuen Kunden in unser System einladen. Weitere Informationen zu dieser Funktion finden Sie im FAQ-Bereich.',
                ],
            },
            realColibriCoin: {
                header: 'RealColibri Coin',
                welcome: 'Entdecken Sie neue Horizonte mit RealColibri Coin!',
                description: `RealColibri Coin (RCC) ist ein Utility-Token, das ausschließlich innerhalb des RealColibri-Ökosystems verwendet wird. Eine Notierung an Börsen ist nicht erforderlich, da sein Nennwert im Smart Contract gesichert ist. Sie können RCC jederzeit direkt über den Smart Contract erwerben, bis die zugewiesene Token-Menge aufgebraucht ist. Danach kann für die freie Zirkulation eine Notierung an zentralisierten oder dezentralisierten Börsen in Betracht gezogen werden.`,
                list: [
                    'Günstige Gebühren: Je höher der Wert von RCC ist, desto weniger Token werden für die automatisierten Handelsdienstleistungen von RealColibri berechnet, wodurch sie für Händler noch zugänglicher und attraktiver werden.',
                    'Wertzuwachs durch Nutzung: Die Verwendung von RCC zur Zahlung von Handelsgebühren erhöht seinen Wert. Im Gegensatz zum direkten Verkauf, bei dem RCC zu einem festen Wert umgetauscht wird, vervielfacht sich sein Wert, wenn es für Gebühren verwendet wird. Zum Beispiel, wenn RCC für 1 $ gekauft wurde und sein Wert sich verzehnfacht hat, sinkt die Gebühr von 50 % auf 5 %, während 95 % als Nettogewinn verbleiben.',
                    'Token-Tausch: Unser Ökosystem ermöglicht es Benutzern auch, RCC untereinander zu verkaufen/zu tauschen, ohne dass RealColibri beteiligt ist.',
                ],
                whatCanIDo: 'Was kann ich mit RCC tun?',
                actions: [
                    'Laden Sie die Gebühreneinlage auf und verwenden Sie RCC als Handelsgebühr für den automatisierten Handel, um seinen Wert zu steigern.',
                    'Verkaufen Sie RCC direkt an einen anderen RealColibri-Teilnehmer, der es erwerben möchte.',
                    'Handeln Sie RCC an Börsen.',
                ],
                joinNow: 'Treten Sie noch heute RealColibri bei und entdecken Sie neue Möglichkeiten und Wege zum finanziellen Erfolg!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Wie starte ich den Handel?',
                        answer: `Um den automatisierten Handel zu starten, benötigen Sie: <br/>
                            Registrieren Sie sich auf unserer Website und verbinden Sie die TronLink-Brieftasche. <br/>
                            Kaufen Sie RCC und füllen Sie die Provisions-Einlage in Ihrem RealColibri-Konto auf. <br/>
                            Verbinden Sie Ihr Handelskonto auf der Handelsseite mit API-Schlüsseln,
                            wählen Sie ein Handelsinstrument aus und starten Sie den Algorithmus.`,
                    },
                    {
                        question:
                            'Mit wie viel kann ich den Handel starten und wie hoch ist der Ertrag?',
                        answer: `Die Mindesteinlage beträgt 10.000 USDT. Die optimale Einlagengröße sollte mindestens
                            das 3,75-fache der Mindesteinlage betragen. Wenn die Einlagengröße kleiner als die optimale
                            Größe ist, hängt der monatliche Gewinn von der Mindesteinlage ab. Wenn die Einlagengröße
                            größer ist als die optimale Größe, wird die Berechnung des Zinseszinses aktiviert, und der monatliche Gewinn hängt
                            von der aktuellen Einlagengröße ab und wird proportional zur Einlagengröße zunehmen. Jeder 5% Gewinn erhöht das
                            Positionsvolumen um 5%. Daher gilt: Je größer die Einlage, desto höher der Ertrag.`,
                    },
                    {
                        question:
                            'Kann ich den automatisierten Handel kostenlos ausprobieren?',
                        answer: `Ja, wir bieten Ihnen die Möglichkeit, den Algorithmus eine Woche lang kostenlos zu nutzen.
                        Während dieses Zeitraums benötigen Sie keine RCC, um den Algorithmus zu nutzen.
                        Um die kostenlose Testphase zu aktivieren, senden Sie eine E-Mail an mail@realcolibri.com mit der entsprechenden Anfrage und Ihrer Tronlink-Wallet-Adresse,
                        mit der Sie sich auf unserer Website registriert haben, und wir werden Ihnen so schnell wie möglich Zugang gewähren.
                        Jeder aktive Kunde, den Sie zu unserem System einladen, erhält garantiert eine zusätzliche Woche Gratiszeit.
                        Laden Sie einfach einen Freund ein, geben Sie die Adresse seiner in unserem System registrierten TronLink-Brieftasche an,
                        und Sie erhalten eine weitere kostenlose Testwoche. Jeder Kunde muss bei RealColibri über TronLink (Full Access) registriert sein,
                        um eine kostenlose Probezeit zu erhalten.`,
                    },
                    {
                        question: 'Wohin geht der Gewinn?',
                        answer: 'Der erzielte Gewinn verbleibt vollständig auf Ihrem Handelskonto.',
                    },
                    {
                        question: 'Wie erwerbe ich RCC?',
                        answer: `Verwenden Sie das Formular in Ihrem RealColibri-Konto oder auf den
                            Seiten Globale Märkte und Kryptomärkte.`,
                    },
                    {
                        question: 'Was kann ich mit RCC tun?',
                        answer: `Finanzieren Sie die Provisions-Einlage und verwenden Sie sie als Provision für die Nutzung des automatisierten Handels. <br/>
                            Verkaufen Sie direkt an ein anderes RealColibri-Mitglied, das RCC kaufen möchte. <br/>
                            Handeln Sie diese an Börsen, sobald RCC in der Zukunft gelistet ist.`,
                    },
                ],
            },
        },
    },
    fr: {
        about: {
            learnMore: 'En savoir plus sur RealColibri',
            autotrading: {
                header: 'Trading Automatisé',
                welcome: 'Bienvenue dans le monde du trading de haute technologie avec RealColibri!',
                description: `RealColibri est un système de trading automatisé avancé spécialisé dans les
                              contrats à terme sur les marchés des cryptomonnaies et mondiaux. Notre système
                              se distingue par son succès à long terme et une approche innovante de l’investissement.`,
                unique: 'Qu’est-ce qui rend le système RealColibri unique?',
                list: [
                    'Résultats exceptionnels : RealColibri offre des performances impressionnantes à long terme. Notre système est basé sur des algorithmes avancés et une analyse approfondie des données, ce qui nous permet d’atteindre une croissance stable et significative des investissements tout en minimisant les risques.',
                    'Approche automatisée : Plus besoin de surveiller constamment le marché! RealColibri analyse automatiquement des milliers de points de données et prend des décisions de trading en temps réel, vous libérant ainsi du stress et améliorant votre efficacité en investissement.',
                    'Diversification : Possibilité de trader différents instruments sur un même compte en créant des paires de clés API supplémentaires ou des combinaisons identifiant-mot de passe et en les utilisant sur plusieurs comptes RealColibri.',
                    'Utilisation du jeton RCC : RealColibri utilise le RCC comme frais de service. Les frais sont calculés en pourcentage des revenus générés sur le compte d’échange. Si la valeur du RCC augmente, le client paie un pourcentage moindre, augmentant ainsi son bénéfice net. Cela rend l’utilisation du jeton plus avantageuse que sa vente.',
                    'Période d’essai gratuite : Testez notre algorithme gratuitement pendant une semaine! Vous pouvez également prolonger cette période d’une semaine supplémentaire pour chaque client que vous invitez dans notre système. Consultez la section FAQ pour plus de détails.',
                ],
            },
            realColibriCoin: {
                header: 'Jeton RealColibri',
                welcome: 'Explorez de nouveaux horizons avec le jeton RealColibri!',
                description: `Le RealColibri Coin (RCC) est un jeton utilitaire utilisé exclusivement au sein de l’écosystème RealColibri. Il n’a pas besoin d’être listé sur les bourses car sa valeur nominale est garantie par un contrat intelligent. Vous pouvez toujours acheter du RCC directement via le contrat intelligent jusqu’à épuisement de l’offre attribuée. Ensuite, pour permettre sa libre circulation, une cotation sur des bourses centralisées ou décentralisées peut être envisagée.`,
                list: [
                    'Frais avantageux : Plus la valeur du RCC est élevée, moins de jetons sont requis pour les services de trading automatisé de RealColibri, les rendant ainsi plus accessibles et attractifs pour les traders.',
                    'Augmentation de la valeur avec l’utilisation : Utiliser le RCC pour payer les frais de trading automatise augmente sa valeur. Contrairement à la vente directe, où le RCC est échangé contre une valeur fixe, son utilisation pour les frais de transaction multiplie sa valeur. Par exemple, si le RCC a été acheté à 1 $ et que sa valeur a été multipliée par 10, les frais passent de 50 % à 5 %, laissant 95 % en bénéfice net.',
                    'Échange de jetons : Notre écosystème permet également aux utilisateurs de vendre/échanger du RCC entre eux sans l’intervention de RealColibri.',
                ],
                whatCanIDo: 'Que puis-je faire avec le RCC?',
                actions: [
                    'Recharger le dépôt de commission et utiliser le RCC comme frais de trading automatisé pour augmenter sa valeur.',
                    'Vendre directement à un autre utilisateur RealColibri souhaitant acheter du RCC.',
                    'Trader le RCC sur les bourses.',
                ],
                joinNow: 'Rejoignez RealColibri dès aujourd’hui et découvrez de nouvelles opportunités et voies vers le succès financier!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Comment commencer à trader?',
                        answer: `Pour commencer le trading automatisé, vous aurez besoin de: <br/>
                            Vous inscrire sur notre site web et connecter le portefeuille TronLink. <br/>
                            Acheter du RCC et recharger le dépôt de commission dans votre compte RealColibri. <br/>
                            Connectez votre compte de trading sur la page de trading en utilisant les clés API,
                            sélectionnez un instrument de trading et lancez l'algorithme.`,
                    },
                    {
                        question:
                            'Avec combien puis-je commencer à trader et quel est le rendement?',
                        answer: `Le dépôt minimum est de 10 000 USDT. La taille optimale du dépôt devrait être au moins
                            3,75 fois la taille du dépôt minimum. Si la taille du dépôt est inférieure à celle optimale,
                            le profit mensuel dépend de la taille du dépôt minimum. Si la taille du dépôt
                            est supérieure à celle optimale, le calcul des intérêts composés est activé, et le profit mensuel dépendra
                            de la taille actuelle du dépôt et augmentera proportionnellement à la taille du dépôt. Chaque 5 % de profit augmente
                            le volume de la position de 5 %. Par conséquent, plus le dépôt est grand, plus le rendement est élevé.`,
                    },
                    {
                        question:
                            'Puis-je essayer le trading automatique gratuitement?',
                        answer: `Oui, nous offrons la possibilité d'utiliser l'algorithme gratuitement pendant une semaine.
                        Pendant cette période, vous n'aurez pas besoin d'avoir RCC pour utiliser l'algorithme. Pour activer la période d'essai gratuite,
                        envoyez un e-mail à mail@realcolibri.com
                        avec la demande appropriée et votre adresse de portefeuille Tronlink
                        avec laquelle vous vous êtes enregistré sur notre site Web et nous vous accorderons l'accès dès que possible.
                        Chaque client actif que vous invitez à notre système est assuré d'obtenir une semaine supplémentaire de temps libre.
                        Il suffit d'inviter un ami, de lui fournir l'adresse de son portefeuille TronLink enregistré dans notre système,
                        et vous obtiendrez une semaine supplémentaire d'essai gratuit.
                        Chaque client doit être enregistré auprès de RealColibri via TronLink (Full Access) pour recevoir un essai de trading gratuit.
                        Inscription et connexion à RealColibri via TronLink`,
                    },
                    {
                        question: 'Où va le profit?',
                        answer: "Le profit obtenu reste entièrement sur votre compte d'échange.",
                    },
                    {
                        question: 'Comment acquérir du RCC?',
                        answer: `Utilisez le formulaire dans votre compte RealColibri ou sur
                            les pages Marchés mondiaux et Marchés des cryptomonnaies.`,
                    },
                    {
                        question: 'Que puis-je faire avec RCC?',
                        answer: `Alimentez le dépôt de commission et utilisez-le comme commission pour l'utilisation du trading automatisé. <br/>
                            Vendez-les directement à un autre membre de RealColibri qui souhaite acheter du RCC. <br/>
                            Tradez-les sur des bourses une fois que RCC sera listé à l'avenir.`,
                    },
                ],
            },
        },
    },
    it: {
        about: {
            learnMore: 'Scopri di più su RealColibri',
            autotrading: {
                header: 'Trading Automatizzato',
                welcome: 'Benvenuti nel mondo del trading ad alta tecnologia con RealColibri!',
                description: `RealColibri è un sistema di trading automatizzato avanzato specializzato nei
                              contratti futures sui mercati delle criptovalute e globali. Il nostro sistema
                              si distingue per il suo successo a lungo termine e un approccio innovativo agli investimenti.`,
                unique: 'Cosa rende unico il sistema RealColibri?',
                list: [
                    'Risultati eccezionali: RealColibri offre prestazioni impressionanti a lungo termine. Il nostro sistema si basa su algoritmi avanzati e un’analisi approfondita dei dati, permettendoci di ottenere una crescita stabile e significativa degli investimenti riducendo al minimo i rischi.',
                    'Approccio automatizzato: Dimentica la necessità di monitorare costantemente il mercato! RealColibri analizza automaticamente migliaia di dati e prende decisioni di trading in tempo reale, liberandoti dallo stress e migliorando la tua efficienza negli investimenti.',
                    'Diversificazione: Possibilità di negoziare diversi strumenti su un unico conto creando coppie di chiavi API aggiuntive o combinazioni di login e password e utilizzandole su più account RealColibri.',
                    'Utilizzo del token RCC: RealColibri utilizza RCC come commissione di servizio. La commissione viene calcolata come percentuale dei profitti generati sul conto di scambio. Se il valore di RCC aumenta, il cliente paga una percentuale inferiore, aumentando così il suo profitto netto. Ciò rende l’uso del token più vantaggioso rispetto alla sua vendita.',
                    'Periodo di prova gratuito: Prova il nostro algoritmo gratuitamente per una settimana! Puoi anche estendere questo periodo di un’altra settimana per ogni cliente che inviti nel nostro sistema. Consulta la sezione FAQ per maggiori dettagli.',
                ],
            },
            realColibriCoin: {
                header: 'Token RealColibri',
                welcome: 'Esplora nuovi orizzonti con il token RealColibri!',
                description: `RealColibri Coin (RCC) è un token utility utilizzato esclusivamente all'interno dell'ecosistema RealColibri. Non è necessario che venga quotato sugli exchange poiché il suo valore nominale è garantito da un contratto intelligente. Puoi sempre acquistare RCC direttamente tramite il contratto intelligente fino all'esaurimento della quantità assegnata. Successivamente, per consentirne la libera circolazione, potrebbe essere considerata la quotazione su exchange centralizzati o decentralizzati.`,
                list: [
                    'Commissioni vantaggiose: Maggiore è il valore di RCC, minore sarà il numero di token richiesti per i servizi di trading automatizzato di RealColibri, rendendoli ancora più accessibili e attraenti per i trader.',
                    'Aumento del valore con l’utilizzo: Usare RCC per pagare le commissioni di trading automatizzato ne aumenta il valore. A differenza della vendita diretta, dove RCC viene scambiato a un valore fisso, il suo utilizzo per il pagamento delle commissioni ne moltiplica il valore. Ad esempio, se RCC è stato acquistato a 1 $ e il suo valore è aumentato di 10 volte, la commissione scende dal 50% al 5%, lasciando il 95% come profitto netto.',
                    'Scambio di token: Il nostro ecosistema consente inoltre agli utenti di vendere/scambiare RCC tra loro senza l’intervento di RealColibri.',
                ],
                whatCanIDo: 'Cosa posso fare con RCC?',
                actions: [
                    'Ricaricare il deposito commissionale e utilizzare RCC come commissione per il trading automatizzato, aumentando il suo valore.',
                    'Vendere direttamente a un altro utente di RealColibri che desidera acquistare RCC.',
                    'Scambiare RCC sugli exchange.',
                ],
                joinNow: 'Unisciti a RealColibri oggi e scopri nuove opportunità e percorsi verso il successo finanziario!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Come inizio a fare trading?',
                        answer: `Per iniziare a fare trading automaticamente avrai bisogno di: <br/>
                        Registrati sul nostro sito web e collega il wallet TronLink. <br/>
                        Acquista RCC e ricarica il deposito della commissione nel tuo account RealColibri. <br/>
                        Collega il tuo account di trading nella pagina di trading utilizzando le chiavi API,
                        seleziona uno strumento di trading e avvia l'algoritmo.`,
                    },
                    {
                        question:
                            'Con quanto posso iniziare a fare trading e qual è il ritorno?',
                        answer: `Il deposito minimo è di 10.000 USDT. La dimensione ottimale del deposito deve essere almeno
                        3,75 volte il deposito minimo. Se la dimensione del deposito è inferiore a quella ottimale,
                        il guadagno mensile dipende dalla dimensione del deposito minimo. Se la dimensione del deposito
                        è superiore a quella ottimale, si abilita il calcolo dell'interesse composto, e il guadagno mensile dipende
                        dalla dimensione attuale del deposito e aumenterà proporzionalmente alla dimensione del deposito. Ogni 5% di guadagno aumenta il
                        volume della posizione del 5%. Pertanto, maggiore è il deposito, maggiore sarà il ritorno.`,
                    },
                    {
                        question:
                            'Posso provare il trading automatico gratuitamente?',
                        answer: `Sì, offriamo l'opportunità di utilizzare l'algoritmo gratuitamente per una settimana.
                    Durante questo periodo non sarà necessario avere RCC per eseguire l'algoritmo. Per attivare il periodo di prova gratuito,
                    invia un'email a mail@realcolibri.com
                    con la richiesta corrispondente e l'indirizzo del tuo wallet Tronlink con cui ti sei registrato sul nostro sito web
                    e ti concederemo l'accesso il prima possibile. Ogni cliente attivo che invita nel nostro sistema ha diritto a una settimana extra di tempo gratuito.
                    Basta invitare un amico, fornirgli l'indirizzo del tuo wallet TronLink registrato nel nostro sistema e otterrai una settimana in più di prova gratuita.
                    Ogni cliente deve essere registrato su RealColibri tramite TronLink (Accesso Completo) per ricevere una prova di trading gratuita.
                    Registrazione e accesso a RealColibri tramite TronLink`,
                    },
                    {
                        question: 'Dove va il guadagno?',
                        answer: 'Il guadagno ricevuto rimane interamente nel tuo account di scambio.',
                    },
                    {
                        question: 'Come acquisisco RCC?',
                        answer: `Utilizza il modulo nel tuo account RealColibri o nelle
                        pagine Mercati Globali e Mercati di Cripto.`,
                    },
                    {
                        question: 'Cosa posso fare con RCC?',
                        answer: `Finanziare il deposito della commissione e utilizzarli come commissione per l'uso dell'automazione del trading. <br/>
                        Venderli direttamente a un altro membro di RealColibri che desidera acquistare RCC. <br/>
                        Scambiarli negli exchange una volta che RCC sarà quotato in futuro.`,
                    },
                ],
            },
        },
    },
}
