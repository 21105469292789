import s from './AutoTrade.module.scss'

import React, { useCallback, useEffect } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl } from 'shared/components'
import { AutoTradeProps } from './AutoTrade.types'
import { RithmicService } from 'api/http'
import { normalizeDataForSelectAndRadio } from 'shared/utils'
import { RithmicStartDto } from 'api/http/models'
import { useFetch } from 'shared/hooks'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ marketData, onMarketFetch, symbol, onSettings, symbols }) => {
        const [onStartFetch] = useFetch(RithmicService.start)
        const [onStopFetch] = useFetch(RithmicService.stop)
        const [form] = Form.useForm()

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: RithmicStartDto) => {
                if (marketData) {
                    if (marketData?.robotId) {
                        await onStopFetch(marketData.id)
                    } else {
                        await onStartFetch(marketData.id, body)
                    }
                    await onMarketFetch()
                }
            },
            [marketData, onMarketFetch]
        )

        /** Обработчик изменения значений полей формы */
        const handleValuesChange = useCallback(
            (changedValue: RithmicStartDto) => {
                const [key, value] = Object.entries(changedValue)[0]

                if (key === 'symbol') {
                    onSettings({
                        symbol: value,
                    })
                }
            },
            [onSettings, symbols]
        )

        useEffect(() => {
            form.setFieldsValue({
                symbol: marketData?.symbol || symbol || symbols?.[0],
            })
        }, [form, marketData?.symbol, symbol, symbols])

        return (
            <Form
                onFinish={handleFinish}
                layout="vertical"
                form={form}
                onValuesChange={handleValuesChange}
            >
                <Row gutter={10}>
                    <Col xs={12}>
                        <Form.Item name="symbol" label="Symbol">
                            <SelectControl
                                disabled={
                                    !!marketData?.robotId || !symbols?.length
                                }
                                options={symbols?.map(
                                    normalizeDataForSelectAndRadio
                                )}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={12}>
                        <Button
                            className={s.button}
                            type="primary"
                            htmlType="submit"
                            danger={!!marketData?.robotId}
                            disabled={!symbols?.length || !marketData}
                        >
                            {marketData?.robotId ? 'STOP' : 'START'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
)
