import s from './Guides.module.scss'
import React, { useMemo } from 'react'
import { PageContent, VideoIFrame } from 'shared/components'
import { Anchor, Button, Col, Drawer, FloatButton, Grid, Row } from 'antd'
import { CONTENT } from './Guides.consts'
import { MenuOutlined } from '@ant-design/icons'
import { useElementVisible } from 'shared/hooks'
import { translations } from './translations'
import { useLanguage } from 'context/LanguageContext'
import { GuideContent } from './types'

/** Гайды */
export const Guides: React.FC = React.memo(() => {
    const { elementVisible, handleHideElement, handleShowElement } =
        useElementVisible()
    const isMobile = !Grid.useBreakpoint().md
    const { language } = useLanguage()

    const contentMenu = useMemo(
        () => (
            <Anchor onClick={handleHideElement}>
                <h2 className={s.contents}>CONTENTS</h2>
                {CONTENT[language].map((el: GuideContent) => (
                    <Anchor.Link
                        key={el.key}
                        href={`#${el.key}`}
                        title={<div className={s.anchor}>{el.title}</div>}
                    >
                        {el.subContent?.map((child, i) => (
                            <Anchor.Link
                                key={child.key}
                                href={`#${child.key}`}
                                title={
                                    <div className={s.anchor}>{`Option ${
                                        i + 1
                                    }`}</div>
                                }
                            />
                        ))}
                    </Anchor.Link>
                ))}
            </Anchor>
        ),
        [handleHideElement, language]
    )

    return (
        <PageContent className={s.wrapper}>
            <h1 className={s.header}>Guides</h1>

            {isMobile && (
                <Drawer
                    placement="left"
                    closable={false}
                    onClose={handleHideElement}
                    open={elementVisible}
                >
                    {contentMenu}
                </Drawer>
            )}

            <Row gutter={20}>
                <Col xs={2} md={8}>
                    {isMobile ? (
                        <Button
                            type="link"
                            onClick={handleShowElement}
                            icon={<MenuOutlined />}
                            className={s.button}
                        />
                    ) : (
                        contentMenu
                    )}
                </Col>

                <Col xs={22} md={16}>
                    {CONTENT[language].map((el: GuideContent) => (
                        <div className={s.guideBlock} key={el.key}>
                            <h3 className={s.title} id={String(el.key)}>
                                {el.title}
                            </h3>

                            {el.video &&
                                el.video.length > 0 &&
                                el.video.map((vidUrl, index) => (
                                    <VideoIFrame
                                        key={index}
                                        src={vidUrl}
                                        className={s.video}
                                    />
                                ))}

                            {el.text ||
                                el.subContent?.map((sub, i) => (
                                    <div id={String(sub.key)} key={sub.key}>
                                        <div className={s.subTitle}>{`Option ${
                                            i + 1
                                        }`}</div>
                                        {sub.steps.map(
                                            (
                                                step: string,
                                                stepIndex: number
                                            ) => (
                                                <div key={stepIndex}>
                                                    {step}
                                                </div>
                                            )
                                        )}
                                    </div>
                                ))}
                        </div>
                    ))}
                </Col>

                <FloatButton.BackTop style={{ top: '30px' }} />
            </Row>
        </PageContent>
    )
})
