import React, { useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Layout as AntdLayout } from 'antd'
import { Header } from './Header'
import { Footer } from './Footer'

export const Layout: React.FC = React.memo(() => {
    const layoutRef = useRef<HTMLElement | null>(null)
    const location = useLocation()

    useEffect(() => {
        layoutRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [location])

    return (
        <AntdLayout ref={layoutRef}>
            <Header />

            <AntdLayout.Content>
                <Outlet />
            </AntdLayout.Content>

            <Footer />
        </AntdLayout>
    )
})
