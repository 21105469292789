import s from './Guides.module.scss'
import React from 'react'
import { translations } from './translations'
import { GuideContent } from './types'

import img0 from 'shared/img/guides/0.png'
import img1 from 'shared/img/guides/1.png'
import img2 from 'shared/img/guides/2.png'
import img3 from 'shared/img/guides/3.png'
import img4 from 'shared/img/guides/4.png'
import img5 from 'shared/img/guides/5.png'
import img6 from 'shared/img/guides/6.png'
import img7 from 'shared/img/guides/7.png'
import img8 from 'shared/img/guides/8.png'
import img9 from 'shared/img/guides/9.png'
import img10 from 'shared/img/guides/10.png'
import img11 from 'shared/img/guides/11.png'
import img12 from 'shared/img/guides/12.png'
import img13 from 'shared/img/guides/13.png'
import img14 from 'shared/img/guides/14.png'
import img23 from 'shared/img/guides/23.png'
import img24 from 'shared/img/guides/24.png'
import img25 from 'shared/img/guides/25.png'
import img26 from 'shared/img/guides/26.png'
import img27 from 'shared/img/guides/27.png'
import img28 from 'shared/img/guides/28.png'
import img29 from 'shared/img/guides/29.png'
import img30 from 'shared/img/guides/30.png'
import img31 from 'shared/img/guides/31.png'
import img32 from 'shared/img/guides/32.png'
import img33 from 'shared/img/guides/33.png'
import img34 from 'shared/img/guides/34.png'
import img40 from 'shared/img/guides/40.png'
import img41 from 'shared/img/guides/41.png'
import img42 from 'shared/img/guides/42.png'
import img43 from 'shared/img/guides/43.png'
import img44 from 'shared/img/guides/44.png'
import img45 from 'shared/img/guides/45.png'

type Lang = keyof typeof translations

export const CONTENT: Record<Lang, GuideContent[]> = (
    Object.keys(translations) as Lang[]
).reduce((acc, lang) => {
    const t = translations[lang].guides

    acc[lang] = [
        {
            title: t.howToCreateTronLink.title,
            key: 1,
            text: (
                <ol>
                    {t.howToCreateTronLink.steps.map(
                        (step: string, index: number) => (
                            <li key={index}>
                                {step}
                                {index === 1 && <img src={img0} alt="img0" />}
                                {index === 2 && <img src={img1} alt="img1" />}
                                {index === 3 && (
                                    <>
                                        <img src={img2} alt="img2" />
                                        <img src={img3} alt="img3" />
                                    </>
                                )}
                                {index === 4 && <img src={img4} alt="img4" />}
                                {index === 5 && <img src={img5} alt="img5" />}
                                {index === 6 && <img src={img6} alt="img6" />}
                                {index === 7 && (
                                    <>
                                        <img src={img7} alt="img7" />
                                        <img src={img8} alt="img8" />
                                    </>
                                )}
                                {index === 8 && <img src={img9} alt="img9" />}
                                {index === 9 && <img src={img10} alt="img10" />}
                                {index === 10 && (
                                    <img src={img11} alt="img11" />
                                )}
                            </li>
                        )
                    )}
                    <div className={s.note}>{t.howToCreateTronLink.note}</div>
                </ol>
            ),
            video: t.howToCreateTronLink.video,
        },
        {
            title: t.howToConnectTronLink.title,
            key: 2,
            text: (
                <ol>
                    {t.howToConnectTronLink.steps.map(
                        (step: string, index: number) => (
                            <li key={index}>
                                {step}
                                {index === 0 && <img src={img12} alt="img12" />}
                                {index === 1 && (
                                    <>
                                        <img src={img13} alt="img13" />
                                        <img src={img14} alt="img14" />
                                    </>
                                )}
                            </li>
                        )
                    )}
                    <div className={s.note}>{t.howToConnectTronLink.note}</div>
                </ol>
            ),
            video: t.howToConnectTronLink.video,
        },
        {
            title: t.howToAddRCBC.title,
            key: 3,
            text: (
                <ol>
                    {t.howToAddRCBC.steps.map((step: string, index: number) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img40} alt="img40" />}
                            {index === 1 && <img src={img41} alt="img41" />}
                            {index === 2 && <img src={img42} alt="img42" />}
                            {index === 3 && <img src={img43} alt="img43" />}
                            {index === 4 && <img src={img44} alt="img44" />}
                            {index === 5 && <img src={img45} alt="img45" />}
                        </li>
                    ))}
                </ol>
            ),
            video: t.howToAddRCBC.video,
        },
        {
            title: t.howToGetRCBC.title,
            key: 4,
            subContent: t.howToGetRCBC.subContent.map(
                (sub: { key: number; steps: string[] }) => ({
                    key: sub.key,
                    steps: sub.steps,
                })
            ),
            video: t.howToGetRCBC.video,
        },
        {
            title: t.howToCreateAndConnectBinanceAPI.title,
            key: 10,
            text: (
                <ol>
                    {t.howToCreateAndConnectBinanceAPI.steps.map(
                        (step: string, index: number) => (
                            <li key={index}>
                                {step}
                                {index === 2 && <img src={img23} alt="img23" />}
                                {index === 3 && <img src={img24} alt="img24" />}
                                {index === 4 && <img src={img25} alt="img25" />}
                                {index === 5 && <img src={img26} alt="img26" />}
                                {index === 6 && <img src={img27} alt="img27" />}
                                {index === 9 && <img src={img28} alt="img28" />}
                                {index === 10 && (
                                    <img src={img29} alt="img29" />
                                )}
                                {index === 13 && (
                                    <img src={img30} alt="img30" />
                                )}
                                {index === 14 && (
                                    <img src={img31} alt="img31" />
                                )}
                                {index === 15 && (
                                    <img src={img32} alt="img32" />
                                )}
                                {index === 16 && (
                                    <img src={img33} alt="img33" />
                                )}
                            </li>
                        )
                    )}
                </ol>
            ),
            video: t.howToCreateAndConnectBinanceAPI.video,
        },
        {
            title: t.howToTopUpCommissionBalance.title,
            key: 11,
            text: (
                <ol>
                    {t.howToTopUpCommissionBalance.steps.map(
                        (step: string, index: number) => (
                            <li key={index}>
                                {step}
                                {index === 1 && <img src={img34} alt="img34" />}
                            </li>
                        )
                    )}
                </ol>
            ),
            video: t.howToTopUpCommissionBalance.video,
        },
    ]

    return acc
}, {} as Record<Lang, GuideContent[]>)
