import React, { useCallback, useEffect } from 'react'
import { SelectControl } from 'shared/components'
import { Button, Form } from 'antd'
import { BinanceActionsProps } from './BinanceActions.types'
import { BinanceService } from 'api/http'
import { useFetch } from 'shared/hooks'

/** Блок с действиями для таблицы аккаунтов */
export const BinanceActions: React.FC<BinanceActionsProps> = React.memo(
    ({ record, onFetchAccounts, symbols }) => {
        const [onStartFetch] = useFetch(BinanceService.start)
        const [onStopFetch] = useFetch(BinanceService.stop)
        const [form] = Form.useForm()

        /** Обработчик формы */
        const handleFinish = useCallback(
            async ({ symbol }: { symbol: number }) => {
                if (record?.robotId) {
                    await onStopFetch(record.id)
                } else if (record) {
                    await onStartFetch(record.id, {
                        symbolId: symbol,
                    })
                }
                onFetchAccounts()
            },
            [record, onFetchAccounts]
        )

        useEffect(() => {
            form.setFieldsValue({
                symbol: record?.symbolId || symbols?.[0]?.id,
            })
        }, [record?.symbolId, form.setFieldsValue, symbols])

        return (
            <Form form={form} onFinish={handleFinish}>
                <Form.Item name="symbol">
                    <SelectControl
                        disabled={!!record?.robotId || !symbols?.length}
                        options={symbols?.map(el => ({
                            value: el.id,
                            key: el.id,
                            label: el.name,
                        }))}
                    />
                </Form.Item>

                <Button
                    block
                    htmlType="submit"
                    type="primary"
                    danger={!!record?.robotId}
                    disabled={!symbols?.length || !record}
                >
                    {record?.robotId ? 'Отключить' : 'Включить'}
                </Button>
            </Form>
        )
    }
)
