import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'antd'
import { BlockWrapper, PageContent, TableAdapter } from 'shared/components'
import { BybitDealsService, BybitService } from 'api/http'
import {
    BaseSearchResponseModel,
    BybitDealDto,
    BybitDealsPeriodStatisticDto,
    BybitDealsSearchDto,
} from 'api/http/models'
import { AccountContext, LoaderContext } from 'core/context'
import { DEAL_TABLE_COLUMNS, QUERY_PARAMS_INITIAL_VALUES } from './Bybit.consts'
import { getTableTitle } from './Bybit.utils'
import { useFetch, useQueryParams } from 'shared/hooks'
import { AutoTrade } from './AutoTrade'
import { Keys } from './Keys'
import { Statistic } from './Statistic'
import translations, { Translations } from './translations'
import { useLanguage } from 'context/LanguageContext'

import s from './Bybit.module.scss'

export const Bybit: React.FC = () => {
    const { accountData } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const { language } = useLanguage()
    const t: Translations = translations[language]

    const [getMarketData, marketData] = useFetch(BybitService.getByAccount)
    const [fetchStatisticSummary, statisticSummary] = useFetch(
        BybitDealsService.getStatisticSummary
    )
    const [deals, setDeals] = useState<BaseSearchResponseModel<BybitDealDto>>()
    const [periodStatistic, setPeriodStatistic] = useState<number>()

    const { paginationOptions, setQueryParams, queryParams } = useQueryParams<
        Omit<BybitDealsSearchDto, 'accountId'>
    >(QUERY_PARAMS_INITIAL_VALUES)

    const handleMarketFetch = useCallback(async () => {
        if (accountData?.id) {
            await getMarketData(accountData.id)
        }
    }, [accountData?.id, getMarketData])

    const handleFetchDeals = useCallback(async () => {
        try {
            setLoader(true)

            if (accountData?.id) {
                const { body, limit, skip } = queryParams

                setDeals(
                    await BybitDealsService.search(skip, limit, {
                        ...body,
                        accountId: accountData.id,
                    } as BybitDealsSearchDto)
                )

                if (body.startDate) {
                    setPeriodStatistic(
                        await BybitDealsService.getPeriodStatistic(
                            accountData.id,
                            body as BybitDealsPeriodStatisticDto
                        )
                    )
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [accountData?.id, queryParams, setLoader])

    useEffect(() => {
        handleMarketFetch()
        handleFetchDeals()
        if (accountData?.id) {
            fetchStatisticSummary(accountData.id)
        }
    }, [
        handleMarketFetch,
        handleFetchDeals,
        fetchStatisticSummary,
        accountData?.id,
    ])

    // Function to show the modal
    const showModal = () => {
        Modal.info({
            title: t.dynamicTrading,
            content: (
                <>
                    <br />
                    <h4>{t.strategyDescription}</h4>
                    <br />
                    <h2>{t.features}</h2>
                    {t.featuresDescription.map((feature, index) => (
                        <p key={`feature-full-${index}`}>• {feature}</p>
                    ))}
                    <br />
                    <h2>{t.advantages}</h2>
                    {t.advantagesDescription.map((advantage, index) => (
                        <p key={`advantage-full-${index}`}>• {advantage}</p>
                    ))}
                </>
            ),
            width: 500,
        })
    }

    return (
        <PageContent>
            <BlockWrapper className={s.notification}>
                <span className={s.importantText}>
                    <b>{t.important}</b>
                </span>{' '}
                <span dangerouslySetInnerHTML={{ __html: t.notification }} />
            </BlockWrapper>

            <Row gutter={15}>
                <Col xs={24} md={16} xl={9}>
                    <BlockWrapper className={s.card}>
                        <h2 className="mb-10">{t.dynamicTrading}</h2>
                        <div>{t.strategyDescription}</div>
                        <div>
                            {/* Read More Link */}
                            <p>
                                <a href="#" onClick={showModal}>
                                    {t.readMore}
                                </a>
                            </p>
                        </div>
                        <AutoTrade
                            marketData={marketData}
                            onMarketFetch={handleMarketFetch}
                        />
                    </BlockWrapper>
                </Col>

                <Col xs={24} md={8} xl={5}>
                    <Keys
                        className={s.card}
                        marketData={marketData}
                        onMarketFetch={handleMarketFetch}
                    />
                </Col>

                <Col xs={24} md={24} xl={10}>
                    <Statistic
                        className={s.card}
                        statisticSummary={statisticSummary}
                        periodStatistic={periodStatistic}
                    />
                </Col>
            </Row>

            {marketData?.isBeStopped && (
                <BlockWrapper className={s.stoppedInfo}>
                    The algorithm is in standby mode and will turn off after the
                    current position is closed. Please note that all your
                    actions in relation to the current position will be accepted
                    by the Algorithm and taken into account when closing the
                    position, including the size of the commission in RCC.
                    <br />
                    <br />
                    Do not place new orders, do not increase or decrease the
                    size of a position without an urgent need to do so. If you
                    close a position manually while the Algorithm is in standby
                    mode, the system will still charge the commission in RCC.
                </BlockWrapper>
            )}

            <TableAdapter
                title={getTableTitle(setQueryParams)}
                columns={DEAL_TABLE_COLUMNS}
                dataSource={deals?.items}
                pagination={{
                    ...paginationOptions,
                    total: deals?.total,
                }}
            />
        </PageContent>
    )
}
