import s from './AutoTrade.module.scss'

import React, { useCallback, useEffect } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl, TextWithRefresh } from 'shared/components'
import { BybitNativeService, BybitService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { BybitStartDto } from 'api/http/models'
import { useWatch } from 'antd/es/form/Form'
import { useFetch } from 'shared/hooks'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ marketData, onFetchMarket, settings, onSettings, symbols }) => {
        const [fetchMinTokenBalance, minTokenBalance] = useFetch(
            BybitNativeService.getMinTokenBalance
        )
        const [onStartFetch] = useFetch(BybitService.start)
        const [onStopFetch] = useFetch(BybitService.stop)
        const [form] = Form.useForm()
        const symbolIdWatch = useWatch('symbolId', form)

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: BybitStartDto) => {
                if (marketData) {
                    if (marketData?.robotId) {
                        await onStopFetch(marketData.id)
                    } else {
                        await onStartFetch(marketData.id, body)
                    }
                    await onFetchMarket()
                }
            },
            [marketData, onFetchMarket]
        )

        const handleMinTokenBalance = useCallback(async () => {
            if (marketData?.id && symbolIdWatch) {
                await fetchMinTokenBalance({
                    symbolId: symbolIdWatch,
                    marketId: marketData.id,
                })
            }
        }, [fetchMinTokenBalance, marketData?.id, symbolIdWatch])

        useEffect(() => {
            if (symbols?.length) {
                form.setFieldValue(
                    'symbolId',
                    marketData?.symbolId || symbols[0].id
                )
            }
        }, [marketData?.symbolId, symbols])

        useEffect(() => {
            if (symbolIdWatch) {
                onSettings({
                    symbol: symbols?.find(el => el.id === symbolIdWatch)
                        ?.symbol,
                })
                handleMinTokenBalance()
            }
        }, [symbolIdWatch, onSettings, symbols, handleMinTokenBalance])

        return (
            <Form onFinish={handleFinish} layout="vertical" form={form}>
                <div className={s.balance}>
                    <div>
                        {`Min balance for start: ${
                            symbols?.find(el => el.symbol === settings?.symbol)
                                ?.minBalance || '-'
                        } USDT`}
                    </div>

                    <TextWithRefresh
                        text={`Min token balance: ${
                            minTokenBalance || '-'
                        } RCC`}
                        onCallback={handleMinTokenBalance}
                    />
                </div>

                <Row gutter={10}>
                    <Col xs={12}>
                        <Form.Item name="symbolId" label="Symbol">
                            <SelectControl
                                disabled={!!marketData?.robotId || !symbols}
                                options={symbols?.map(el => ({
                                    label: el.name,
                                    value: el.id,
                                    key: el.id,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={12}>
                        <Button
                            className={s.button}
                            type="primary"
                            htmlType="submit"
                            danger={!!marketData?.robotId}
                            disabled={
                                !symbols?.length ||
                                !marketData ||
                                marketData.isBeStopped
                            }
                        >
                            {marketData?.robotId ? 'STOP' : 'START'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
)
