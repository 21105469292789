export const AUTH = 'auth'
export const BINANCE_NATIVE = 'binance-native'
export const BINANCE = 'binance'
export const BINANCE_DEALS = 'binance-deals'
export const BINANCE_SYMBOLS = 'binance-symbols'
export const ACCOUNT = 'account'
export const RITHMIC = 'rithmic'
export const BYBIT_NATIVE = 'bybit-native'
export const BYBIT = 'bybit'
export const BYBIT_SYMBOLS = 'bybit-symbols'
export const BYBIT_DEALS = 'bybit-deals'
