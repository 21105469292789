import React, { useCallback, useEffect, useState } from 'react'
import { BlockWrapper, QuestionIconTip, TableAdapter } from 'shared/components'
import { Button } from 'antd'
import s from './LiveTrading.module.scss'
import { DEAL_TABLE_COLUMNS, TIMER_COUNT } from './LiveTrading.consts'
import { LiveTradingResponseModel } from 'api/http/models'
import { BinanceDealsService } from 'api/http'
import { useLanguage } from 'context/LanguageContext' // Assuming you have a language context for managing translations
import { translations } from './translations' // Import translations

export const LiveTrading: React.FC = React.memo(() => {
    const { language } = useLanguage()
    const {
        title,
        description,
        totalCommission,
        totalProfit,
        refreshButton,
        allTimeReport,
        reviewHistory,
        updatesManually,
    } = translations[language]

    const [localLoader, setLocalLoader] = useState(false)
    const [tableData, setTableData] = useState<LiveTradingResponseModel[]>()
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
    const [timer, setTimer] = useState(TIMER_COUNT)

    const startTimer = useCallback(() => {
        const intervalId = setInterval(() => {
            setTimer((currentValue: number) => {
                if (!currentValue) {
                    clearInterval(intervalId)
                    return currentValue
                }
                return currentValue - 1
            })
        }, 1000)

        setIntervalId(intervalId)
    }, [])

    const fetch = useCallback(async () => {
        try {
            setLocalLoader(true)
            setTimer(TIMER_COUNT)
            setTableData(await BinanceDealsService.getLiveTrading())
        } catch (e) {
            console.log(e)
        } finally {
            setLocalLoader(false)
        }
    }, [])

    const handleAllReport = useCallback(() => {
        window.open(
            'https://docs.google.com/spreadsheets/d/1_Vzo1tAJaAwLcFvW5Z0xOV6k7Mtm96KV_mkIu1ICRUQ',
            '_blank',
            'width=800,height=600,top=100,left=200'
        )
    }, [])

    useEffect(() => {
        if (timer === TIMER_COUNT) {
            startTimer()
        }
    }, [startTimer, timer])

    useEffect(() => {
        fetch()
    }, [fetch])

    useEffect(() => {
        if (intervalId) {
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [intervalId])

    return (
        <BlockWrapper className={s.wrapper} id="trade">
            <h2 className={s.title}>{title}</h2>

            <div className={s.description}>
                {description.replace(
                    '{count}',
                    String(tableData?.length || 1000)
                )}
            </div>

            <div className={s.total}>
                <div>
                    <b>{totalCommission}</b>
                    <span>
                        {tableData
                            ?.reduce<number>(
                                (acc, el) => acc + el.market_commission,
                                0
                            )
                            .toFixed(2)}
                        &nbsp;USDT
                    </span>
                </div>

                <div>
                    <b>{totalProfit}</b>
                    <span>
                        {tableData
                            ?.reduce<number>((acc, el) => acc + el.profit, 0)
                            .toFixed(2)}
                        &nbsp;USDT
                    </span>
                </div>
            </div>

            <TableAdapter
                columns={DEAL_TABLE_COLUMNS}
                dataSource={tableData}
                loading={localLoader}
                rowClassName={s.row}
                rowKey="create_date"
                pagination={false}
                scroll={{ x: 500, y: 300 }}
            />

            <div
                className={s.description}
                style={{ textTransform: 'uppercase' }}
            >
                {reviewHistory}
                <br />
                <Button
                    className={s.button}
                    onClick={handleAllReport}
                    type="primary"
                >
                    {allTimeReport}
                </Button>
                <QuestionIconTip title={updatesManually} />
            </div>
        </BlockWrapper>
    )
})
