import { TronWebProps } from './tron.types'
import { notification } from 'antd'
import { RCBC_ABI } from './abi'
import { RCBC_ADDRESS } from './tron.consts'

class TronLinkHelper {
    tronWeb?: TronWebProps
    base58Address?: string
    instanceRcbc?: Record<string, any>

    private _connect = async (): Promise<boolean> => {
        const dataSource = await window.tronLink.request({
            method: 'tron_requestAccounts',
        })

        if (dataSource?.code === 200) {
            return true
        }

        notification.error({
            message: `TronLink error ${dataSource?.code || ''}`,
            description: dataSource?.message || 'Something went wrong',
        })

        return false
    }

    private _getInstanceContract = async (address: string, abi?: any[]) => {
        try {
            const instance = await this.tronWeb?.contract?.().at(address)
            if (abi) {
                instance?.loadAbi(abi)
            }

            return instance
        } catch (e) {
            console.log(e)
        }
    }

    initTronWeb = async () => {
        if (!(await this._connect())) {
            throw Error
        }

        this.tronWeb = window.tronLink.tronWeb

        if (
            process.env.NODE_ENV === 'production' &&
            this.tronWeb.fullNode.host !== 'https://api.trongrid.io'
        ) {
            notification.warning({
                message: 'Not mainnet',
                description:
                    'Please, change mainnet in TronLink and refresh page',
            })

            throw Error
        }

        if (
            process.env.NODE_ENV === 'development' &&
            this.tronWeb.fullNode.host !== 'https://api.nileex.io'
        ) {
            notification.warning({
                message: 'Not testnet',
                description:
                    'Please, change testnet in TronLink and refresh page',
            })

            throw Error
        }

        this.instanceRcbc = await this._getInstanceContract(
            RCBC_ADDRESS,
            RCBC_ABI
        )

        this.base58Address = this.tronWeb.defaultAddress.base58
    }

    signMessage = async (nonce: string): Promise<string | undefined> =>
        await this.tronWeb?.trx.signMessage(nonce)
}

export const TronLink = new TronLinkHelper()
