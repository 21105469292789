import s from './Profile.module.scss'

import React, { useContext } from 'react'
import { BlockWrapper, ExchangeForm } from 'shared/components'
import { Deposit } from '../Deposit'
import { Col, Row } from 'antd'
import { AccountContext } from 'core/context'

/** Создание сервисного контракта для действующего клиента */
export const Profile: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext)
    return (
        <Row gutter={[20, 20]} className={s.wrapper}>
            <Col xs={24} lg={12}>
                <BlockWrapper>
                    <div className={s.text}>
                        <p>
                            Exchange TRX for RCC to pay a commission for using
                            the algorithm.
                        </p>
                    </div>

                    <ExchangeForm />

                    {!accountData?.tronAddress && (
                        <div className={s.text}>
                            <p>
                                Refresh the page after installation. You will be
                                prompted to connect your wallet to the website.
                            </p>
                        </div>
                    )}
                </BlockWrapper>
            </Col>

            <Col xs={24} lg={12}>
                <Deposit />
            </Col>
        </Row>
    )
})
