import s from './AutoTrade.module.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Space } from 'antd'
import {
    QuestionIconTip,
    SelectControl,
    TextWithRefresh,
} from 'shared/components'
import { BinanceService, BinanceSymbolsService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { Store } from 'antd/lib/form/interface'
import {
    BinanceSymbolsForSelectResponseModel,
    MarketDataStartRequestModel,
} from 'api/http/models'
import { useFetch } from 'shared/hooks'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ marketData, onMarketFetch }) => {
        const [form] = Form.useForm()
        const [onStartFetch] = useFetch(BinanceService.start)
        const [onStopFetch] = useFetch(BinanceService.stop)
        const [getSymbols, symbols] = useFetch(BinanceSymbolsService.forSelect)
        const [getMinTokenBalance, minTokenBalance] = useFetch(
            BinanceSymbolsService.getMinTokenBalance
        )
        const [symbolData, setSymbolData] =
            useState<BinanceSymbolsForSelectResponseModel>()

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: MarketDataStartRequestModel) => {
                if (marketData) {
                    if (marketData?.robotId) {
                        await onStopFetch(marketData.id)
                    } else {
                        await onStartFetch(marketData.id, body)
                    }
                    await onMarketFetch()
                }
            },
            [onMarketFetch, marketData]
        )

        const handleValuesChange = useCallback(
            (valueChanged: Store) => {
                const [key, value] = Object.entries(valueChanged)[0]
                if (key === 'symbolId') {
                    setSymbolData(symbols?.find(el => el.id === value))
                }
            },
            [symbols]
        )

        const handleMinTokenBalance = useCallback(async () => {
            if (symbolData) {
                await getMinTokenBalance({
                    symbol_id: symbolData.id,
                })
            }
        }, [symbolData])

        useEffect(() => {
            handleMinTokenBalance()
        }, [handleMinTokenBalance])

        useEffect(() => {
            getSymbols()
        }, [])

        useEffect(() => {
            if (symbols?.length) {
                const symbol =
                    symbols.find(el => el.id === marketData?.symbolId) ||
                    symbols[0]
                form.setFieldValue('symbolId', symbol.id)
                setSymbolData(symbol)
            }
        }, [marketData?.symbolId, symbols])

        return (
            <div>
                <div className={s.balance}>
                    <div>{`Min deposit size: ${
                        symbolData?.min_balance || '-'
                    } USDT`}</div>

                    <Space>
                        <TextWithRefresh
                            text={`Min token balance: ${
                                minTokenBalance || '-'
                            } RCC`}
                            onCallback={handleMinTokenBalance}
                        />

                        <QuestionIconTip title="Actual minimal amount is calculated from your actual trading deposit size. Refresh to recalculate it" />
                    </Space>
                </div>

                <Form
                    onFinish={handleFinish}
                    layout="vertical"
                    form={form}
                    onValuesChange={handleValuesChange}
                >
                    <Row gutter={10}>
                        <Col xs={24} sm={12}>
                            <Form.Item name="symbolId" label="Symbol">
                                <SelectControl
                                    disabled={
                                        !!marketData?.robotId ||
                                        !symbols?.length
                                    }
                                    options={symbols?.map(el => ({
                                        value: el.id,
                                        key: el.id,
                                        label: el.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Button
                                className={s.button}
                                type="primary"
                                htmlType="submit"
                                danger={!!marketData?.robotId}
                                disabled={!symbols?.length || !marketData}
                            >
                                {marketData?.robotId ? 'STOP' : 'START'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
)
