import { AreaChartOutlined, DollarOutlined } from '@ant-design/icons'

interface InfoItem {
    text: string
    icon: React.ComponentType<any>
}

interface Translations {
    [key: string]: {
        title: string
        description: string
        button: string
        info: InfoItem[]
    }
}

export const translations: Translations = {
    en: {
        title: 'WHAT REALCOLIBRI IS',
        description:
            'Advanced trading automation system with a unique trading algorithm at its core',
        button: 'LEARN MORE',
        info: [
            {
                text: 'A stable and efficient automated trading algorithm for trading futures contracts on global and crypto markets',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin is an internal utility token used as a commission for using the algorithm and more',
                icon: DollarOutlined,
            },
        ],
    },
    es: {
        title: 'QUÉ ES REALCOLIBRI',
        description:
            'Sistema avanzado de automatización comercial con un algoritmo de trading único en su núcleo',
        button: 'APRENDER MÁS',
        info: [
            {
                text: 'Un algoritmo de trading automatizado estable y eficiente para operar contratos futuros en mercados globales y criptográficos',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin es un token de utilidad interno utilizado como comisión por el uso del algoritmo y más',
                icon: DollarOutlined,
            },
        ],
    },
    ru: {
        title: 'ЧТО ТАКОЕ REALCOLIBRI',
        description:
            'Современная система автоматизации торговли с уникальным торговым алгоритмом в основе',
        button: 'УЗНАТЬ БОЛЬШЕ',
        info: [
            {
                text: 'Стабильный и эффективный алгоритм автоматизированной торговли для торговли фьючерсами на мировых и криптовалютных рынках',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin — внутренний утилитарный токен, используемый в качестве комиссии за использование алгоритма и более',
                icon: DollarOutlined,
            },
        ],
    },
    hy: {
        title: 'ԻՆՉ Է REALCOLIBRI-Ը',
        description:
            'Առևտրի ավտոմատացման առաջադեմ համակարգ՝ եզակի առևտրային ալգորիթմով',
        button: 'ԻՄԱՆԱԼ ԱՎԵԼԻՆ',
        info: [
            {
                text: 'Կայուն և արդյունավետ ավտոմատացված առևտրային ալգորիթմ՝ ֆյուչերսային պայմանագրերի առևտրի համար համաշխարհային և կրիպտո շուկաներում',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin-ը ներքին օգտակար թոքեն է, որը օգտագործվում է որպես ալգորիթմի օգտագործման միջնորդավճար և ավելին',
                icon: DollarOutlined,
            },
        ],
    },
    de: {
        title: 'WAS IST REALCOLIBRI',
        description:
            'Fortschrittliches Handelsautomatisierungssystem mit einem einzigartigen Handelsalgorithmus im Kern',
        button: 'MEHR ERFAHREN',
        info: [
            {
                text: 'Ein stabiles und effizientes automatisiertes Handelssystem für den Handel mit Terminkontrakten auf globalen und Krypto-Märkten',
                icon: AreaChartOutlined,
            },
            {
                text: 'RealColibri Coin ist ein interner Utility-Token, der als Provision für die Nutzung des Algorithmus und mehr verwendet wird',
                icon: DollarOutlined,
            },
        ],
    },
    fr: {
        title: "QU'EST-CE QUE REALCOLIBRI",
        description:
            "Système avancé d'automatisation du trading avec un algorithme de trading unique au cœur",
        button: 'EN SAVOIR PLUS',
        info: [
            {
                text: 'Un algorithme de trading automatisé stable et efficace pour le trading de contrats à terme sur les marchés mondiaux et crypto',
                icon: AreaChartOutlined,
            },
            {
                text: "RealColibri Coin est un token utilitaire interne utilisé comme commission pour l'utilisation de l'algorithme et plus encore",
                icon: DollarOutlined,
            },
        ],
    },
    it: {
        title: "COS'È REALCOLIBRI",
        description:
            'Sistema avanzato di automazione del trading con un algoritmo di trading unico al centro',
        button: 'SCOPRI DI PIÙ',
        info: [
            {
                text: 'Un algoritmo di trading automatizzato stabile ed efficiente per il trading di contratti futures sui mercati globali e delle criptovalute',
                icon: AreaChartOutlined,
            },
            {
                text: "RealColibri Coin è un token di utilità interno utilizzato come commissione per l'uso dell'algoritmo e altro",
                icon: DollarOutlined,
            },
        ],
    },
}
