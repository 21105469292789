import s from './Deposit.module.scss'

import React, { useCallback, useContext } from 'react'
import { BlockWrapper, InputNumberControl } from 'shared/components'
import { Button, Form } from 'antd'
import { AccountContext, LoaderContext } from 'core/context'
import { fixedNumber, noExponents } from 'shared/utils'
import { MAX_FEE_LIMIT, RCBC_PRECISION } from 'core/tron/tron.consts'
import { AccountService } from 'api/http'
import { TronLink } from 'core/tron'
import { AccountDepositModel } from 'api/http/models'
import { useFetch } from 'shared/hooks'

/** Пополнение токен баланса робота */
export const Deposit: React.FC = React.memo(() => {
    const { accountData, auth } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const [fetchWithdraw] = useFetch(AccountService.withdraw)

    const handleFinish = useCallback(
        async (values: AccountDepositModel) => {
            try {
                setLoader(true)

                const [whole, fraction] = noExponents(values.deposit).split('.')
                let valuePrecision = fraction || ''
                if (valuePrecision.length < RCBC_PRECISION) {
                    valuePrecision =
                        valuePrecision +
                        '0'.repeat(RCBC_PRECISION - valuePrecision.length)
                }
                const callValue = `${whole}${valuePrecision}`

                if (callValue && TronLink.instanceRcbc) {
                    await TronLink.instanceRcbc
                        .transfer(
                            process.env.REACT_APP_DEPOSIT_ADDRESS,
                            callValue
                        )
                        .send({
                            shouldPollResponse: true,
                            feeLimit: MAX_FEE_LIMIT,
                        })
                    await AccountService.deposit(values)
                    await auth()
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, auth]
    )

    const handleWithdraw = useCallback(async () => {
        await fetchWithdraw()
        await auth()
    }, [])

    return (
        <BlockWrapper>
            <div className={s.balance}>
                <div>
                    {`Commission deposit: ${fixedNumber(
                        accountData?.deposit || 0
                    )} RCC`}
                </div>

                {!!accountData?.locked && (
                    <div>{`Locked deposit: ${accountData.locked} RCC`}</div>
                )}
            </div>

            <Form onFinish={handleFinish} disabled={!accountData} size="large">
                <Form.Item name="deposit">
                    <InputNumberControl
                        min={1}
                        max={1000000000000}
                        placeholder="1,000,000"
                        addonAfter="RCC"
                        className={s.input}
                    />
                </Form.Item>

                <Button
                    className={s.button}
                    htmlType="submit"
                    type="primary"
                    block
                    size="large"
                >
                    Deposit
                </Button>
                <Button
                    type="default"
                    block
                    size="large"
                    onClick={handleWithdraw}
                    disabled={!accountData?.deposit}
                >
                    Withdraw
                </Button>
            </Form>
        </BlockWrapper>
    )
})
