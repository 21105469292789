import dayjs from 'dayjs'
import { ColumnsType } from 'antd/lib/table'
import { DealsResponseModel } from 'api/http/models'
import {
    defaultRuleSortingTableNumberColumn,
    fixedNumber,
    formatDate,
} from 'shared/utils'

/** Настройки отображения столбцов для таблицы сделок робота */
export const DEAL_TABLE_COLUMNS: ColumnsType<DealsResponseModel> = [
    {
        title: 'Time',
        dataIndex: 'create_date',
        key: 'create_date',
        render: formatDate,
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('create_date'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('size'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Entry price',
        dataIndex: 'entry_price',
        key: 'entry_price',
        align: 'center',
    },
    {
        title: 'Exit price',
        dataIndex: 'exit_price',
        key: 'exit_price',
        align: 'center',
    },
    {
        title: 'Market commission',
        dataIndex: 'market_commission',
        key: 'market_commission',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('market_commission'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'RealColibri commission (RCC)',
        dataIndex: 'commission',
        key: 'commission',
        render: commission => fixedNumber(commission),
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('commission'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        key: 'profit',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('profit'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
]

export const QUERY_PARAMS_INITIAL_VALUES = {
    start_date: 0,
    end_date: dayjs().endOf('day').valueOf(),
}
