import s from './ExchangeForm.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LoaderContext } from 'core/context'
import { Button, Form, Modal } from 'antd'
import {
    MAX_FEE_LIMIT,
    RCBC_PRECISION,
    TRX_PRECISION,
} from 'core/tron/tron.consts'
import { TronLink } from 'core/tron'
import { InputNumberControl } from 'shared/components/InputNumberControl'
import { TronLinkGuide } from 'shared/components/TronLinkGuide'
import { getCoinSelect, getNotification } from './ExchangeForm.utils'
import Icon from '@ant-design/icons'
import { ReactComponent as RcbcIcon } from 'shared/img/logo.svg'
import { ReactComponent as TrxIcon } from 'shared/img/trx-logo.svg'
import { ExchangeFormProps } from './ExchangeForm.types'

/** Обмен монеты */
export const ExchangeForm: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const [coin, setCoin] = useState('trx')
    const [coinCount, setCoinCount] = useState('')
    const [form] = Form.useForm<ExchangeFormProps>()
    const coinField = Form.useWatch(coin, form)

    const isTrx = coin === 'trx'

    const handleChangeCoin = useCallback(
        (coin: string) => {
            form.resetFields()
            setCoinCount('')
            setCoin(coin)
        },
        [form]
    )

    const handleFinish = useCallback(
        async (values: ExchangeFormProps) => {
            try {
                setLoader(true)

                if (!TronLink.tronWeb) {
                    Modal.warning({
                        centered: true,
                        title: 'TronLink',
                        content: TronLinkGuide,
                    })

                    return
                }

                const coinCountValue = isTrx ? values.trx : values.rcbc

                if (coinCountValue && TronLink.instanceRcbc) {
                    const [whole, fraction] = String(coinCountValue).split('.')
                    let valuePrecision = fraction || ''
                    const precisionCount = isTrx
                        ? TRX_PRECISION
                        : RCBC_PRECISION
                    if (valuePrecision.length < precisionCount) {
                        valuePrecision =
                            valuePrecision +
                            '0'.repeat(precisionCount - valuePrecision.length)
                    }
                    const callValue = `${whole}${valuePrecision}`
                    const result = isTrx
                        ? await TronLink.instanceRcbc
                              ?.buy()
                              .send({ callValue, feeLimit: MAX_FEE_LIMIT })
                        : await TronLink.instanceRcbc
                              ?.sell(callValue)
                              .send({ feeLimit: MAX_FEE_LIMIT })

                    if (result) {
                        getNotification(result)
                        form.setFieldsValue({ trx: undefined, rcbc: undefined })
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, isTrx, form]
    )

    useEffect(() => {
        if (coinField) {
            const whole = coinField / (isTrx ? 10 : 100)
            if (whole) {
                let fraction = String(whole).split('.')[1] || ''
                const precisionCount = isTrx ? TRX_PRECISION : RCBC_PRECISION
                if (fraction.length > precisionCount) {
                    fraction = fraction.slice(0, precisionCount)
                }
                setCoinCount(
                    `${Math.trunc(whole)}${fraction ? `.${fraction}` : ''}`
                )
                return
            }
        }

        setCoinCount('')
    }, [coinField, isTrx])

    return (
        <Form form={form} onFinish={handleFinish} size="large">
            <Form.Item
                name={coin}
                className={s.input}
                rules={[{ required: true }]}
            >
                <InputNumberControl
                    prefix="CONVERT:"
                    min={coin === 'trx' ? 0.00001 : 0.0001}
                    max={coin === 'trx' ? 10000000000000 : 1000000000000}
                    step={1}
                    formatter={value => {
                        if (!value) {
                            return String(value || '')
                        }
                        const [whole, fraction] = String(value).split('.')
                        const precisionCount = isTrx
                            ? TRX_PRECISION
                            : RCBC_PRECISION
                        return (fraction || '').length > precisionCount
                            ? `${whole}.${fraction.slice(0, precisionCount)}`
                            : String(value || '')
                    }}
                    placeholder="1,000,000"
                    addonAfter={getCoinSelect(coin, handleChangeCoin)}
                />
            </Form.Item>

            <div className={s.blockGet}>
                <span className={s.label}>RECEIVE:</span>

                <div className={s.exchangeCoin}>
                    <div>{coinCount || '-'}</div>

                    <div className={s.coin}>
                        <Icon
                            component={isTrx ? RcbcIcon : TrxIcon}
                            className={s.icon}
                        />

                        <span>{isTrx ? 'RCC' : 'TRX'}</span>
                    </div>
                </div>
            </div>

            {TronLink.tronWeb ? (
                <Button type="primary" htmlType="submit" block>
                    Exchange
                </Button>
            ) : (
                <Button
                    type="primary"
                    href="https://www.tronlink.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={s.register}
                    block
                >
                    Install TronLink
                </Button>
            )}
        </Form>
    )
})
