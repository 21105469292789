import React from 'react'
import { PageContent } from 'shared/components'
import { Profile } from './Profile'

/** Страница аккаунта */
export const Account: React.FC = React.memo(() => {
    return (
        <PageContent>
            <Profile />
        </PageContent>
    )
})
