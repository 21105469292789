import s from './RegisterForm.module.scss'
import React, { useCallback, useContext, useState } from 'react'
import { Button, Col, Divider, Form, Row } from 'antd'
import { AccountService, AuthService } from 'api/http'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import Logo from 'shared/img/logo.svg'
import { CheckboxControl } from 'shared/components/CheckboxControl'
import {
    InputControl,
    InputPasswordControl,
} from 'shared/components/InputControl'
import { Link } from 'react-router-dom'
import { NAV_LINK_NAMES } from 'shared/consts'
import cn from 'classnames'
import { AccountContext, LoaderContext } from 'core/context'
import { AccountCreateModel } from 'api/http/models'
import ReCAPTCHA from 'react-google-recaptcha'
import translations from './translations'
import { useLanguage } from 'context/LanguageContext'
import { useFetch } from 'shared/hooks'

export const RegisterForm: React.FC<PopupAdapterFormProps> = React.memo(
    ({ onRequestFinish, id }) => {
        const [form] = Form.useForm()
        const { auth, tronLinkRegister } = useContext(AccountContext)
        const { isLoader } = useContext(LoaderContext)
        const [onFetchCreate] = useFetch(AccountService.create)
        const [onFetchLogin] = useFetch(AuthService.login)
        const [isTerms, setIsTerms] = useState(false) // Initially unchecked
        const [captchaToken, setCaptchaToken] = useState<string | null>(null) // Track CAPTCHA token
        const [captchaError, setCaptchaError] = useState(false)

        const { language } = useLanguage() // Accessing the current language from the context
        const t = translations[language] // Get the translation object for the current language

        const handleFinish = useCallback(
            async (values: AccountCreateModel) => {
                if (!captchaToken) {
                    setCaptchaError(true)
                    return
                }
                if (!isTerms) return

                await onFetchCreate(values)
                await onFetchLogin(values)
                await auth()

                onRequestFinish?.()
            },
            [auth, onRequestFinish, isTerms, captchaToken]
        )

        const handleCaptcha = useCallback(
            async (token: string | null) => {
                setCaptchaToken(token) // Update CAPTCHA token in state
                form.setFieldsValue({ token }) // Sync with form
            },
            [form]
        )

        return (
            <Form<AccountCreateModel>
                id={id}
                form={form}
                onFinish={handleFinish}
                validateMessages={{ required: t.REQUIRED_FIELD }}
                className={s.wrapper}
                size="large"
            >
                <img src={Logo} alt="Logo" className={s.img} />

                <h3 className={s.title}>{t.CREATE_ACCOUNT}</h3>

                <Row className={s.row} gutter={0}>
                    <Col xs={24} sm={11}>
                        <h3 className={s.sub_title}>{t.AUTOTRADING_ONLY}</h3>

                        <p className={s.terms}>
                            Automate your trading and increase your capital.{' '}
                            {t.REFRESH_PAGE}
                        </p>

                        <div>
                            <Button
                                href="https://www.tronlink.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                                type="primary"
                                className={s.button}
                            >
                                {t.INSTALL_TRONLINK}
                            </Button>

                            <p className={s.terms}>{t.REFRESH_PAGE}</p>
                        </div>

                        <Form.Item
                            name="username"
                            rules={[{ required: true }]}
                            getValueFromEvent={({ target }) =>
                                target.value.replace(/\W/g, '')
                            }
                        >
                            <InputControl
                                placeholder={t.USERNAME_PLACEHOLDER}
                                maxLength={50}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true }]}
                            getValueFromEvent={({ target }) =>
                                target.value.replace(/\s/g, '')
                            }
                        >
                            <InputPasswordControl
                                placeholder={t.PASSWORD_PLACEHOLDER}
                                maxLength={100}
                                minLength={6}
                            />
                        </Form.Item>

                        <CheckboxControl
                            className={cn(s.terms, !isTerms && s.termsError)}
                            checked={isTerms}
                            onChange={e => {
                                setIsTerms(e.target.checked)
                            }}
                        >
                            <span>{t.TERMS_AGREE}</span>

                            <br />

                            <Link
                                to={NAV_LINK_NAMES.TERMS}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t.TERMS_CONDITIONS}
                            </Link>
                        </CheckboxControl>

                        {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                            <div className={cn(captchaError && s.captcha)}>
                                <ReCAPTCHA
                                    sitekey={
                                        process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                    }
                                    onChange={handleCaptcha}
                                />

                                <Form.Item name="token" hidden>
                                    <InputControl />
                                </Form.Item>
                            </div>
                        )}

                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            className={s.button}
                            disabled={!isTerms || !captchaToken}
                        >
                            {t.REGISTER}
                        </Button>
                    </Col>

                    <Col xs={24} sm={2}>
                        <Divider
                            type={
                                window.innerWidth < 576
                                    ? 'horizontal'
                                    : 'vertical'
                            }
                            style={{ height: '100%' }}
                        />
                    </Col>

                    <Col xs={24} sm={11}>
                        <h3 className={s.sub_title}>{t.COMPLETE_ACCESS}</h3>

                        {!isLoader && (
                            <>
                                <Button
                                    href="https://www.tronlink.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    type="primary"
                                    className={s.button}
                                >
                                    {t.INSTALL_TRONLINK}
                                </Button>

                                <p className={s.terms}>{t.REFRESH_PAGE}</p>

                                <Button
                                    onClick={tronLinkRegister}
                                    className={s.button}
                                    type="primary"
                                >
                                    {t.REGISTER_WITH_TRONLINK}
                                </Button>
                            </>
                        )}
                    </Col>
                </Row>
            </Form>
        )
    }
)
