const translations = {
    en: {
        CREATE_ACCOUNT: 'CREATE AN ACCOUNT',
        AUTOTRADING_ONLY: 'AUTOTRADING ONLY',
        COMPLETE_ACCESS: 'COMPLETE ACCESS',
        INSTALL_TRONLINK: 'Install TronLink',
        REFRESH_PAGE:
            'Refresh the page after installation. You will be prompted to connect your wallet to the website. TronLink wallet will allow you to acquire RCC to pay a commission for using the algorithm.',
        USERNAME_PLACEHOLDER: 'Username',
        PASSWORD_PLACEHOLDER: 'Password',
        TERMS_AGREE: "I have read and agree to RealColibri's",
        TERMS_CONDITIONS: 'Terms & Conditions',
        REGISTER: 'Register',
        REQUIRED_FIELD: 'required field',
        REGISTER_WITH_TRONLINK: 'Register with TronLink',
        CAPTCHA_ERROR: 'Please complete the captcha',
    },
    es: {
        CREATE_ACCOUNT: 'CREAR UNA CUENTA',
        AUTOTRADING_ONLY: 'SÓLO AUTOTRADING',
        COMPLETE_ACCESS: 'ACCESO COMPLETO',
        INSTALL_TRONLINK: 'Instalar TronLink',
        REFRESH_PAGE:
            'Actualice la página después de la instalación. Se le pedirá que conecte su billetera al sitio web. La billetera TronLink le permitirá adquirir RCC para pagar una comisión por usar el algoritmo.',
        USERNAME_PLACEHOLDER: 'Nombre de usuario',
        PASSWORD_PLACEHOLDER: 'Contraseña',
        TERMS_AGREE: 'He leído y acepto los',
        TERMS_CONDITIONS: 'Términos y Condiciones',
        REGISTER: 'Registrarse',
        REQUIRED_FIELD: 'campo requerido',
        REGISTER_WITH_TRONLINK: 'Registrarse con TronLink',
        CAPTCHA_ERROR: 'Por favor complete el captcha',
    },
    ru: {
        CREATE_ACCOUNT: 'СОЗДАТЬ УЧЕТНУЮ ЗАПИСЬ',
        AUTOTRADING_ONLY: 'ТОЛЬКО АВТОТРЕЙДИНГ',
        COMPLETE_ACCESS: 'ПОЛНЫЙ ДОСТУП',
        INSTALL_TRONLINK: 'Установить TronLink',
        REFRESH_PAGE:
            'Обновите страницу после установки. Вам будет предложено подключить ваш кошелек к сайту. Кошелек TronLink позволит вам приобрести RCC для оплаты комиссии за использование алгоритма.',
        USERNAME_PLACEHOLDER: 'Имя пользователя',
        PASSWORD_PLACEHOLDER: 'Пароль',
        TERMS_AGREE: 'Я прочитал и согласен с',
        TERMS_CONDITIONS: 'Условиями и положениями',
        REGISTER: 'Зарегистрироваться',
        REQUIRED_FIELD: 'обязательное поле',
        REGISTER_WITH_TRONLINK: 'Зарегистрироваться с TronLink',
        CAPTCHA_ERROR: 'Пожалуйста, завершите капчу',
    },
    hy: {
        CREATE_ACCOUNT: 'ՍՏԵՂԾԵԼ ՀԱՇԻՎ',
        AUTOTRADING_ONLY: 'ՄԻԱՅՆ ԱՎՏՈՏՐԵՅԴԻՆԳ',
        COMPLETE_ACCESS: 'ԱՄԲՈՂՋԱԿԱՆ ՄՏԱԾԵԼՈՒԹՅՈՒՆ',
        INSTALL_TRONLINK: 'Տեղադրել TronLink-ը',
        REFRESH_PAGE:
            'Թարմացրեք էջը տեղադրումից հետո: Ձեզ կառաջարկվի միացնել ձեր դրամապանակը կայքին: TronLink դրամապանակը թույլ կտա ձեռք բերել RCC՝ ալգորիթմի օգտագործման համար վճարելու համար:',
        USERNAME_PLACEHOLDER: 'Օգտանուն',
        PASSWORD_PLACEHOLDER: 'Գաղտնաբառ',
        TERMS_AGREE: 'Ես կարդացել եմ և համաձայն եմ RealColibri-ի',
        TERMS_CONDITIONS: 'Պայմաններն ու դրույթները',
        REGISTER: 'Գրանցվել',
        REQUIRED_FIELD: 'պարտադիր դաշտ',
        REGISTER_WITH_TRONLINK: 'Գրանցվել TronLink-ով',
        CAPTCHA_ERROR: 'Խնդրում ենք լրացնել captcha-ն',
    },
    fr: {
        CREATE_ACCOUNT: 'CRÉER UN COMPTE',
        AUTOTRADING_ONLY: 'AUTOTRADING UNIQUEMENT',
        COMPLETE_ACCESS: 'ACCÈS COMPLET',
        INSTALL_TRONLINK: 'Installer TronLink',
        REFRESH_PAGE:
            "Rafraîchissez la page après l'installation. Il vous sera demandé de connecter votre portefeuille au site Web. Le portefeuille TronLink vous permettra d'acquérir des RCC pour payer une commission pour l'utilisation de l'algorithme.",
        USERNAME_PLACEHOLDER: "Nom d'utilisateur",
        PASSWORD_PLACEHOLDER: 'Mot de passe',
        TERMS_AGREE: "J'ai lu et j'accepte les",
        TERMS_CONDITIONS: 'Termes et Conditions',
        REGISTER: "S'inscrire",
        REQUIRED_FIELD: 'champ requis',
        REGISTER_WITH_TRONLINK: "S'inscrire avec TronLink",
        CAPTCHA_ERROR: 'Veuillez compléter le captcha',
    },
    de: {
        CREATE_ACCOUNT: 'KONTO ERSTELLEN',
        AUTOTRADING_ONLY: 'NUR AUTOTRADING',
        COMPLETE_ACCESS: 'VOLLER ZUGRIFF',
        INSTALL_TRONLINK: 'TronLink installieren',
        REFRESH_PAGE:
            'Aktualisieren Sie die Seite nach der Installation. Sie werden aufgefordert, Ihre Wallet mit der Website zu verbinden. TronLink-Wallet ermöglicht es Ihnen, RCC zu erwerben, um eine Provision für die Nutzung des Algorithmus zu zahlen.',
        USERNAME_PLACEHOLDER: 'Benutzername',
        PASSWORD_PLACEHOLDER: 'Passwort',
        TERMS_AGREE: 'Ich habe gelesen und stimme den',
        TERMS_CONDITIONS: 'Nutzungsbedingungen zu',
        REGISTER: 'Registrieren',
        REQUIRED_FIELD: 'Pflichtfeld',
        REGISTER_WITH_TRONLINK: 'Mit TronLink registrieren',
        CAPTCHA_ERROR: 'Bitte vervollständigen Sie das Captcha',
    },
    it: {
        CREATE_ACCOUNT: 'CREA ACCOUNT',
        AUTOTRADING_ONLY: 'SOLO AUTOTRADING',
        COMPLETE_ACCESS: 'ACCESSO COMPLETO',
        INSTALL_TRONLINK: 'Installa TronLink',
        REFRESH_PAGE:
            "Aggiorna la pagina dopo l'installazione. Ti verrà chiesto di connettere il tuo wallet al sito. Il wallet TronLink ti permette di acquistare RCC per pagare una commissione per l'uso dell'algoritmo.",
        USERNAME_PLACEHOLDER: 'Nome utente',
        PASSWORD_PLACEHOLDER: 'Password',
        TERMS_AGREE: 'Ho letto e accetto i',
        TERMS_CONDITIONS: 'Termini e Condizioni',
        REGISTER: 'Registrati',
        REQUIRED_FIELD: 'Campo obbligatorio',
        REGISTER_WITH_TRONLINK: 'Registrati con TronLink',
        CAPTCHA_ERROR: 'Completa il Captcha',
    },
}

export default translations
