import s from './BybitAccounts.module.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { BybitService, BybitSymbolsService } from 'api/http'
import { CheckboxControl, PageContent, TableAdapter } from 'shared/components'
import { getAccountsTableColumns } from './BybitAccounts.utils'
import { BybitDataModel, BybitUpdateDto } from 'api/http/models'
import { useFetch, useQueryParams } from 'shared/hooks'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Button, Modal, Space } from 'antd'

/** Таблица пользователей */
export const BybitAccounts: React.FC = React.memo(() => {
    const [fetchSymbols, symbols] = useFetch(BybitSymbolsService.getForSelect)
    const [getBybitData, bybitData] = useFetch(BybitService.search)
    const [onFetchBybitUpdate] = useFetch(BybitService.update)
    const [onFetchHardStopAll] = useFetch(BybitService.hardStopAll)
    const { paginationOptions, queryParams, setQueryParams } = useQueryParams({
        isRun: false,
    })
    const [selectedKeys, setSelectedKeys] = useState<number[]>([])

    /** Запрос за данными аккаунтов */
    const fetchAccounts = useCallback(async () => {
        const {
            body: { isRun },
            limit,
            skip,
        } = queryParams

        if (typeof isRun === 'boolean') {
            await getBybitData(skip, limit, {
                isRun,
            })
        }
    }, [queryParams])

    const handleIsRun = useCallback(
        (value: CheckboxChangeEvent) => {
            setQueryParams(prevState => ({
                ...prevState,
                body: {
                    isRun: value.target.checked,
                },
            }))
        },
        [setQueryParams]
    )

    const handleMarketUpdate = useCallback(
        async (marketId: number, body: BybitUpdateDto) => {
            await onFetchBybitUpdate(marketId, body)
            await fetchAccounts()
        },
        [fetchAccounts, onFetchBybitUpdate]
    )

    const handleHardStopAll = useCallback(() => {
        Modal.confirm({
            title: 'Attention!',
            content: selectedKeys.length
                ? `${selectedKeys.length} robots will be stopped. Continue?`
                : 'All work will be stopped. Continue?',
            onOk: async () => {
                await onFetchHardStopAll({
                    ids: selectedKeys.length ? selectedKeys : undefined,
                })
                await fetchAccounts()
            },
        })
    }, [selectedKeys, onFetchHardStopAll, fetchAccounts])

    const handleSelectedKey = useCallback(
        ({ id }: BybitDataModel, selected: boolean) => {
            setSelectedKeys(prevState =>
                selected
                    ? [...prevState, id]
                    : prevState.filter(el => el !== id)
            )
        },
        []
    )

    useEffect(() => {
        fetchAccounts()
        fetchSymbols()
    }, [fetchAccounts, fetchSymbols])

    return (
        <PageContent>
            <div className={s.header}>
                <h2>Bybit accounts</h2>

                <Space>
                    <CheckboxControl onChange={handleIsRun}>
                        Показать только включенные
                    </CheckboxControl>

                    <Button danger type="primary" onClick={handleHardStopAll}>
                        Hard stop
                    </Button>
                </Space>
            </div>

            <TableAdapter
                columns={getAccountsTableColumns(
                    fetchAccounts,
                    handleMarketUpdate,
                    symbols
                )}
                dataSource={bybitData?.items || []}
                rowSelection={{
                    getCheckboxProps: record => ({
                        disabled: !record.robotId,
                    }),
                    hideSelectAll: true,
                    selectedRowKeys: selectedKeys,
                    onSelect: handleSelectedKey,
                }}
                pagination={{
                    ...paginationOptions,
                    total: bybitData?.total || 0,
                }}
            />
        </PageContent>
    )
})
