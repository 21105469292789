import React from 'react'
import { NotImplementedError } from 'core/errors'

import { AccountContextProps } from './AccountContext.types'

export const AccountContext = React.createContext<AccountContextProps>({
    accountData: undefined,
    auth: () => {
        throw new NotImplementedError()
    },
    tronLinkAuth: () => {
        throw new NotImplementedError()
    },
    tronLinkRegister: () => {
        throw new NotImplementedError()
    },
})
