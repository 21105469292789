import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row, Modal } from 'antd'
import { BlockWrapper, PageContent, TableAdapter } from 'shared/components'
import { BinanceDealsService, BinanceService } from 'api/http'
import { DealsRequestModel } from 'api/http/models'
import { AccountContext, LoaderContext } from 'core/context'
import {
    DEAL_TABLE_COLUMNS,
    QUERY_PARAMS_INITIAL_VALUES,
} from './Binance.consts'
import { StatisticProps } from './Binance.types'
import { getTableTitle } from './Binance.utils'
import { useFetch, useQueryParams } from 'shared/hooks'
import { AutoTrade } from './AutoTrade'
import { BinanceKeys } from './BinanceKeys'
import { BinanceStatistic } from './BinanceStatistic'
import translations, { Translations } from './translations'
import { useLanguage } from 'context/LanguageContext'

import s from './Binance.module.scss'

/** Trading Robot on Binance */
export const Binance: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const [getMarketData, marketData] = useFetch(BinanceService.get)
    const [getDeals, deals] = useFetch(BinanceDealsService.search)
    const [statistic, setStatistic] = useState<StatisticProps>()
    const { language } = useLanguage() // Use language context

    const t: Translations = translations[language]

    const { paginationOptions, queryParams, setQueryParams } =
        useQueryParams<DealsRequestModel>(QUERY_PARAMS_INITIAL_VALUES)

    const handleMarketFetch = useCallback(async () => {
        if (accountData?.id) {
            await getMarketData(accountData.id)
        }
    }, [accountData?.id, getMarketData])

    /** Fetch profit statistics */
    const fetchProfitStatistic = useCallback(async () => {
        try {
            setLoader(true)

            let periodStatisticData
            const { body } = queryParams
            if (body.start_date) {
                periodStatisticData =
                    await BinanceDealsService.getPeriodStatistic({
                        ...(body as DealsRequestModel),
                    })
            }

            setStatistic({
                ...(await BinanceDealsService.getStatistic()),
                amount: periodStatisticData?.amount,
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader, queryParams])

    /** Fetch table data */
    const fetchTableDate = useCallback(async () => {
        const { body, limit, skip } = queryParams
        await getDeals(body as DealsRequestModel, skip, limit)
    }, [queryParams])

    useEffect(() => {
        handleMarketFetch()
        fetchTableDate()
        fetchProfitStatistic()
    }, [])

    const showModal = () => {
        Modal.info({
            title: t.dynamicTrading,
            content: (
                <>
                    <br />
                    <h4>{t.strategyDescription}</h4>
                    <br />
                    <h2>{t.features}</h2>
                    {t.featuresDescription.map((feature, index) => (
                        <p key={`feature-full-${index}`}>• {feature}</p>
                    ))}
                    <br />
                    <h2>{t.advantages}</h2>
                    {t.advantagesDescription.map((advantage, index) => (
                        <p key={`advantage-full-${index}`}>• {advantage}</p>
                    ))}
                </>
            ),
            width: 500,
        })
    }

    return (
        <PageContent>
            <BlockWrapper className={s.notification}>
                <span className={s.importantText}>
                    <b>{t.important}</b>
                </span>{' '}
                <span dangerouslySetInnerHTML={{ __html: t.notification }} />
            </BlockWrapper>

            <Row gutter={15}>
                <Col xs={24} md={16} xl={9}>
                    <BlockWrapper className={s.card}>
                        <h2 className="mb-10">{t.dynamicTrading}</h2>

                        <div>{t.strategyDescription}</div>
                        <div>
                            {/* Read More Link */}
                            <p>
                                <a href="#" onClick={showModal}>
                                    {t.readMore}
                                </a>
                            </p>
                        </div>

                        <AutoTrade
                            marketData={marketData}
                            onMarketFetch={handleMarketFetch}
                        />
                    </BlockWrapper>
                </Col>

                <Col xs={24} md={8} lg={5}>
                    <BinanceKeys className={s.card} />
                </Col>

                <Col xs={24} md={24} lg={8}>
                    <BinanceStatistic
                        className={s.card}
                        statistic={statistic}
                    />
                </Col>
            </Row>

            <TableAdapter
                title={getTableTitle(setQueryParams)}
                columns={DEAL_TABLE_COLUMNS}
                dataSource={deals?.items}
                pagination={{
                    ...paginationOptions,
                    total: deals?.total || 0,
                }}
            />
        </PageContent>
    )
})
