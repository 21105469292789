import dayjs from 'dayjs'
import { LabeledValue } from 'antd/lib/select'
import { RCBC_PRECISION } from 'core/tron/tron.consts'
import { POPUPS_TARGET } from '../consts'

/**
 * Форматирование даты для вывода в UI
 * @param date дата
 */
export const formatDate = (date?: number) => {
    if (date) {
        return dayjs(date).format('DD.MM.YYYY HH:mm:ss')
    }
}

/**
 * Нормализация данных, для вывода в SelectControl\MultiSelectControl\Radio
 * @param el очередная запись итерации
 */
export const normalizeDataForSelectAndRadio = (
    el: number | string
): LabeledValue => ({
    label: el,
    value: el,
    key: String(el),
})

/**
 * Функция для сортировки в таблицах
 * @param sortedField название поля для сортировки
 */
export const defaultRuleSortingTableNumberColumn =
    <T>(sortedField: keyof T) =>
    (a: T, b: T): number => {
        const firstValue = Number(a[sortedField])
        const secondValue = Number(b[sortedField])
        if (firstValue && secondValue) return firstValue - secondValue
        return 0
    }

/**
 * Конвертация формата экспоненты в человеческий формат отображения числа
 * @param exponents число-экспонента
 */
export const noExponents = (exponents: number): string => {
    const data = String(exponents).split(/[eE]/)

    if (data.length === 1) {
        return data[0]
    }

    let z = ''
    const sign = exponents < 0 ? '-' : ''
    const str = data[0].replace('.', '')
    let mag = Number(data[1]) + 1

    if (mag < 0) {
        z = sign + '0.'
        while (mag++) z += '0'

        return `${z}${str.replace(/^-/, '')}`
    }

    mag -= str.length

    while (mag--) {
        z += '0'
    }

    return `${str}${z}`
}

export const removeZeroInStringEnd = (
    strNumber: string,
    precision = RCBC_PRECISION
) => {
    if (strNumber.length <= precision) {
        let circle = 0
        for (let i = strNumber.length - 1; i >= 0; i--) {
            if (strNumber[i] !== '0' || circle === strNumber.length) {
                const floatString = strNumber.slice(
                    -precision,
                    circle ? -circle : undefined
                )
                if (floatString)
                    return `0.${'0'.repeat(precision - circle)}${floatString}`
                else return '0'
            }
            circle += 1
        }
    }

    if (strNumber.length > precision) {
        const intString = strNumber.slice(0, strNumber.length - precision)
        let circle = 0
        for (let i = strNumber.length - 1; i >= 0; i--) {
            if (strNumber[i] !== '0' || circle === precision) {
                const floatString = strNumber.slice(
                    -precision,
                    circle ? -circle : undefined
                )
                if (floatString) return `${intString}.${floatString}`
                else return intString
            }
            circle += 1
        }
    }

    return '0'
}

export const fixedNumber = (value: number | string, fixed = 4) =>
    Number(Number(value).toFixed(fixed))

export const getContainer = () =>
    document.querySelector(`#${POPUPS_TARGET}`) as HTMLElement
