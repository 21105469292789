export type Translations = {
    important: string
    notification: string
    dynamicTrading: string
    strategyDescription: string
    readMore: string
    features: string
    advantages: string
    featuresDescription: string[]
    advantagesDescription: string[]
}

const translations: Record<string, Translations> = {
    en: {
        important: 'Important!',
        notification: `Do not interfere with the algorithm after its launch.
        Interference in the trading process may lead to undesirable consequences.
        Do not restart the algorithm if the open position volume is higher than the initial one,
        do not cancel or set orders on the exchange manually. Even if you know what you are doing,
        please consult us first on any issue by emailing <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri is not responsible for any actions taken on your trading account while the algorithm is running without first consulting us upon written request.`,
        dynamicTrading:
            'Compound interest with dynamic trade volume for deposit growth',
        strategyDescription:
            'The strategy accelerates deposit growth by increasing trade volume by 1% of the initial size for every 1% capital growth.',
        features: 'Features:',
        advantages: 'Advantages:',
        readMore: 'Read More...',
        featuresDescription: [
            'Fixed risk regardless of deposit size.',
            'Increase in trade volume proportional to deposit growth.',
            'Each percentage of return amplifies the compound interest effect.',
        ],
        advantagesDescription: [
            'Rapid deposit growth with a stable strategy.',
            'Maximum efficiency with minimal controlled risk.',
        ],
    },
    ru: {
        important: 'Важно!',
        notification: `Не вмешивайтесь в алгоритм после его запуска.
        Вмешательство в торговый процесс может привести к нежелательным последствиям.
        Не перезапускайте алгоритм, если объем открытой позиции больше первоначального,
        не отменяйте и не ставьте ордера на бирже вручную. Даже если вы знаете, что делаете,
        пожалуйста, сначала проконсультируйтесь с нами по любому вопросу, написав на <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri не несет ответственности за действия, совершенные на вашем торговом счете, в то время как алгоритм работает без предварительной консультации с нами по письменному запросу.`,
        dynamicTrading:
            'Сложный процент с динамическим объемом торгов для роста депозита',
        strategyDescription:
            'Стратегия ускоряет рост депозита, увеличивая объем торговли на 1% от первоначального размера за каждый 1% роста капитала.',
        features: 'Особенности:',
        advantages: 'Преимущества:',
        readMore: 'Читать далее...',
        featuresDescription: [
            'Фиксированный риск, независимо от размера депозита.',
            'Увеличение объема сделки пропорционально росту депозита.',
            'Каждый процент доходности усиливает эффект сложного процента.',
        ],
        advantagesDescription: [
            'Быстрый рост депозита при стабильной стратегии.',
            'Максимальная эффективность при минимальном контролируемом риске.',
        ],
    },
    es: {
        important: '¡Importante!',
        notification: `No interfiera con el algoritmo después de su lanzamiento.
        La interferencia en el proceso de negociación puede llevar a consecuencias no deseadas.
        No reinicie el algoritmo si el volumen de la posición abierta es mayor que el inicial,
        no cancele ni coloque órdenes manualmente en el intercambio. Incluso si sabe lo que está haciendo,
        por favor, consúltenos primero sobre cualquier problema enviando un correo a <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri no se responsabiliza de ninguna acción tomada en su cuenta de trading mientras el algoritmo esté en funcionamiento sin antes consultar con nosotros mediante solicitud escrita.`,
        dynamicTrading:
            'Interés compuesto con volumen de comercio dinámico para el crecimiento del depósito',
        strategyDescription:
            'La estrategia acelera el crecimiento del depósito aumentando el volumen de comercio en un 1% del tamaño inicial por cada 1% de crecimiento del capital.',
        features: 'Características:',
        advantages: 'Ventajas:',
        readMore: 'Leer más...',
        featuresDescription: [
            'Riesgo fijo sin importar el tamaño del depósito.',
            'Aumento del volumen de comercio proporcional al crecimiento del depósito.',
            'Cada porcentaje de rendimiento amplifica el efecto del interés compuesto.',
        ],
        advantagesDescription: [
            'Crecimiento rápido del depósito con una estrategia estable.',
            'Máxima eficiencia con el riesgo controlado mínimo.',
        ],
    },
    it: {
        important: 'Importante!',
        notification: `Non interferire con l'algoritmo dopo il suo avvio.
        L'interferenza nel processo di trading può portare a conseguenze indesiderate.
        Non riavviare l'algoritmo se il volume della posizione aperta è maggiore di quello iniziale,
        non cancellare né impostare ordini manualmente sull'exchange. Anche se sai cosa stai facendo,
        consultaci prima su qualsiasi problema inviando una email a <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri non è responsabile per alcuna azione intrapresa sul tuo conto di trading mentre l'algoritmo è in funzione senza prima consultarsi con noi su richiesta scritta.`,
        dynamicTrading:
            'Interesse composto con volume di trading dinamico per la crescita del deposito',
        strategyDescription:
            "La strategia accelera la crescita del deposito aumentando il volume di trading dell'1% rispetto alla dimensione iniziale per ogni 1% di crescita del capitale.",
        features: 'Caratteristiche:',
        advantages: 'Vantaggi:',
        readMore: 'Leggi di più...',
        featuresDescription: [
            'Rischio fisso indipendentemente dalle dimensioni del deposito.',
            'Aumento del volume di trading proporzionale alla crescita del deposito.',
            "Ogni percentuale di rendimento amplifica l'effetto dell'interesse composto.",
        ],
        advantagesDescription: [
            'Crescita rapida del deposito con una strategia stabile.',
            'Massima efficienza con il rischio controllato minimo.',
        ],
    },
    fr: {
        important: 'Important!',
        notification: `Ne vous mêlez pas de l'algorithme après son lancement.
        L'interférence dans le processus de trading peut entraîner des conséquences indésirables.
        Ne redémarrez pas l'algorithme si le volume de la position ouverte est plus élevé que celui initial,
        ne modifiez ni n'annulez les ordres manuellement sur la bourse. Même si vous savez ce que vous faites,
        consultez-nous d'abord pour toute question en envoyant un e-mail à <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri n'est pas responsable des actions entreprises sur votre compte de trading tant que l'algorithme est en fonctionnement sans d'abord consulter avec nous sur demande écrite.`,
        dynamicTrading:
            'Intérêt composé avec volume de trading dynamique pour la croissance du dépôt',
        strategyDescription:
            'La stratégie accélère la croissance du dépôt en augmentant le volume de trading de 1% de la taille initiale pour chaque 1% de croissance du capital.',
        features: 'Caractéristiques:',
        advantages: 'Avantages:',
        readMore: 'Lire la suite...',
        featuresDescription: [
            'Risque fixe indépendamment de la taille du dépôt.',
            'Augmentation du volume de trading proportionnellement à la croissance du dépôt.',
            "Chaque pourcentage de rendement amplifie l'effet des intérêts composés.",
        ],
        advantagesDescription: [
            'Croissance rapide du dépôt avec une stratégie stable.',
            'Efficacité maximale avec un risque contrôlé minimal.',
        ],
    },
    de: {
        important: 'Wichtig!',
        notification: `Stören Sie nicht den Algorithmus nach dem Start.
        Störungen im Handelsprozess können unerwünschte Folgen haben.
        Starten Sie den Algorithmus nicht neu, wenn das Volumen der offenen Position höher als das ursprüngliche ist,
        stornieren Sie keine Bestellungen oder setzen Sie manuell Aufträge auf der Börse. Selbst wenn Sie wissen, was Sie tun,
        konsultieren Sie uns zuerst bei Fragen per E-Mail an <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri ist nicht verantwortlich für Aktionen, die auf Ihrem Handelskonto ausgeführt werden, während der Algorithmus läuft, ohne uns vorher auf schriftliche Anfrage zu konsultieren.`,
        dynamicTrading:
            'Zinseszinsen mit dynamischem Handelsvolumen für das Wachstum des Depots',
        strategyDescription:
            'Die Strategie beschleunigt das Depotwachstum, indem sie das Handelsvolumen um 1% der anfänglichen Größe für jedes 1% Wachstum des Kapitals erhöht.',
        features: 'Merkmale:',
        advantages: 'Vorteile:',
        readMore: 'Mehr lesen...',
        featuresDescription: [
            'Fester Risikowert unabhängig von der Depotgröße.',
            'Steigerung des Handelsvolumens proportional zum Depotwachstum.',
            'Jeder Prozentsatz der Rendite verstärkt den Zinseszinseffekt.',
        ],
        advantagesDescription: [
            'Schnelles Depotwachstum mit einer stabilen Strategie.',
            'Maximale Effizienz mit minimalem kontrolliertem Risiko.',
        ],
    },
    hy: {
        important: 'Կարևոր!',
        notification: `Խառնվեք ալգորիթմին նրա գործարկումից հետո:
        Հայտնի գործընթացում միջամտությունը կարող է հանգեցնել անհանգստացնող հետևանքների:
        Ալգորիթմը չի վերագործարկեք, եթե բացված դիրքի ծավալը գերազանցում է սկզբնականը,
        մի արգելեք կամ կարգավորեք պատվերներ առևտրի հարթակում ձեռքով: Հաշվի առեք, եթե գիտեք, թե ինչ եք անում,
        խնդրում ենք նախորդեք մեզ ցանկացած հարցով, գրելով էլեկտրոնային փոստով <a href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
        RealColibri-ն պատասխանատու չէ ցանկացած գործողությունների համար, որոնք կատարում եք ձեր առևտրային հաշվում, երբ ալգորիթմը գործում է առանց նախնական խորհրդատվություն:`,
        dynamicTrading:
            'Բարդ տոկոսներ՝ դինամիկ առևտրի ծավալով՝ ավանդի աճի համար',
        strategyDescription:
            'Ակտիվությունը արագացնում է ավանդի աճը՝ ավելացնելով առևտրի ծավալը 1%-ով՝ սկզբնական չափից՝ յուրաքանչյուր 1%-ով կապիտալի աճի համար:',
        features: 'Հատկություններ:',
        advantages: 'Առավելություններ:',
        readMore: 'Կարդալ ավելին...',
        featuresDescription: [
            'Պայմանավորված ռիսկ՝ առանց ավանդի չափի փոփոխության:',
            'Անհատական ծավալի ավելացում՝ համապատասխանաբար ավանդի աճի:',
            'Յուրաքանչյուր տոկոսային եկամտաբերությունը մեծացնում է բարդ տոկոսադրույքի ազդեցությունը:',
        ],
        advantagesDescription: [
            'Ավանդի արագ աճ՝ կայուն ռազմավարությամբ:',
            'Առավելագույն արդյունավետություն՝ նվազագույն վերահսկվող ռիսկով:',
        ],
    },
}

export default translations
