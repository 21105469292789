import React from 'react'
import { SelectControl } from 'shared/components/SelectControl'
import { COINS } from './ExchangeForm.consts'
import { FnActionRequiredProps } from 'App.types'
import { notification } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import s from './ExchangeForm.module.scss'

export const getCoinSelect = (
    defaultCoin: string,
    onChange?: FnActionRequiredProps<string>
) => (
    <SelectControl
        defaultValue={defaultCoin}
        options={COINS}
        onChange={onChange}
    />
)

export const getNotification = (result: string) =>
    notification.info({
        message: (
            <Paragraph
                copyable
                ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: 'more',
                }}
                className={s.resultMessage}
            >
                {`TxID: ${result}`}
            </Paragraph>
        ),
        description: (
            <div>
                Check on&nbsp;
                <a
                    href={`https://${
                        process.env.NODE_ENV === 'production' ? '' : 'nile.'
                    }tronscan.org/#/transaction/${result}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    TRONSCAN
                </a>
            </div>
        ),
        duration: 30,
    })
